import paymentApi from '@/api/payment'
import Vue from 'vue'
const trows = {
    basic: [
        { id: 1, title: 'O365 Mail', checked: true },
        // { id: 2, title: 'O365 Calendar', checked: false },
        { id: 3, title: 'O365 OneDrive', checked: false },
        { id: 4, title: 'O365 SharePoint', checked: false },
        { id: 5, title: 'O365 Teams', checked: false },
        { id: 6, title: 'Fildrev (on-prem)', checked: false },
        { id: 7, title: 'Exchange (on-prem)', checked: false },
        { id: 8, title: 'SharePoint (on-prem)', checked: false }
    ],
    business: [
        { id: 1, title: 'O365 Mail', checked: true },
        // { id: 2, title: 'O365 Calendar', checked: true },
        { id: 3, title: 'O365 OneDrive', checked: true },
        { id: 4, title: 'O365 SharePoint', checked: true },
        { id: 5, title: 'O365 Teams', checked: true },
        { id: 6, title: 'Fildrev (on-prem)', checked: false },
        { id: 7, title: 'Exchange (on-prem)', checked: false },
        { id: 8, title: 'SharePoint (on-prem)', checked: false }
    ],
    premium: [
        { id: 1, title: 'O365 Mail', checked: true },
        // { id: 2, title: 'O365 Calendar', checked: true },
        { id: 3, title: 'O365 OneDrive', checked: true },
        { id: 4, title: 'O365 SharePoint', checked: true },
        { id: 5, title: 'O365 Teams', checked: true },
        { id: 6, title: 'Fildrev (on-prem)', checked: true },
        { id: 7, title: 'Exchange (on-prem)', checked: true },
        { id: 8, title: 'SharePoint (on-prem)', checked: true }
    ]
}

export default {
    namespaced: true,
    state: () => ({
        plans: [],
        planLength: {},
        plan: 'business',
        card: {},
        enterprisePlan: 0,
        loaded: false,
        currency: 'dkk',
        allPlans: null,
        users: 1
    }),
    actions: {
        getPlans: ({ state, commit, dispatch }, payload) => {
            paymentApi.getPrices().then(response => {
                commit('setPlans', {
                    plans: response.data.plan,
                    planLength: response.data.plan_length
                })
                commit('setPaymentPlan')
                commit('getChoosenPlan')
            })
        }
    },
    mutations: {
        setPlans: (state, payload) => {
            let labels = {
                basic: 'D&M for Exchange Online',
                business: 'D&M for Microsoft365',
                premium: 'D&M for Hybrid'
            }
            for (let plans of Object.values(payload.plans)) {
                for (let plan of plans) {
                    plan.label = labels[plan.id]
                }
            }

            state.allPlans = JSON.parse(JSON.stringify(payload.plans))
            state.plans = payload.plans[state.currency]
            for (let plan in state.plans) {
                state.plans[plan].trows = trows[state.plans[plan].id]
                Vue.set(state.plans[plan], 'selected', false)
            }
            state.planLength = payload.planLength
            const index = state.plans[0].enterprise.findIndex(el => state.users >= el.from && state.users <= el.to)
            state.enterprisePlan = index

            state.loaded = true
        },
        choosePlan: (state, payload) => {
            state.plans.forEach(element => {
                element.selected = false
            })
            const index = state.plans.findIndex(el => el.id === payload.id)
            state.plans[index].selected = true
            state.plan = state.plans[index].id
            state.card = state.plans[index]
            const data = {
                plan: state.plan,
                enterprisePlan: state.enterprisePlan,
                users: state.users
            }
            localStorage.setItem('paymentDetails', JSON.stringify(data))
        },
        choosePaymentModel: (state, payload) => {
            if (payload.enterprise >= 0) {
                state.enterprisePlan = payload.enterprise
                state.users = state.card.enterprise[state.enterprisePlan].to
            } else {
                state.enterprisePlan = 0
                state.users = 1
            }
            const data = {
                plan: state.plan,
                enterprisePlan: state.enterprisePlan,
                users: state.users
            }
            localStorage.setItem('paymentDetails', JSON.stringify(data))
        },
        setPaymentPlan: (state, payload) => {
            state.plans.forEach(element => {
                element.selected = false
            })
            const index = state.plans.findIndex(el => el.id === state.plan)
            state.plans[index].selected = true
        },
        setPayment: (state, payload) => {
            state.users = payload.users
            state.plan = payload.plan
            const data = {
                plan: state.plan,
                enterprisePlan: state.enterprisePlan,
                users: state.users
            }
            localStorage.setItem('paymentDetails', JSON.stringify(data))
        },
        getPaymentDetails: (state) => {
            if (localStorage.getItem('paymentDetails') !== null) {
                const data = JSON.parse(localStorage.getItem('paymentDetails'))
                state.plan = data.plan
                state.enterprisePlan = data.enterprisePlan
                state.users = data.users
            }
        },
        getChoosenPlan: (state) => {
            const index = state.plans.findIndex(el => el.id === state.plan)
            state.card = state.plans[index]
        },
        setCurrency: (state, payload) => {
            if (payload.value === 'DK') {
                state.currency = 'dkk'
            } else {
                state.currency = 'eur'
            }
            for (let plan in state.plans) {
                Vue.set(state.plans[plan], 'regular', state.allPlans[state.currency][plan].regular)
                Vue.set(state.plans[plan], 'enterprise', state.allPlans[state.currency][plan].enterprise)
            }
        }
    }
}
