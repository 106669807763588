import Vue from 'vue'
import VueRouter from 'vue-router'
import Signup from '@/components/account/SignUp'
import Signin from '@/components/account/SignIn'
import Overview from '@/components/pages/Overview'
import ChangePlan from '@/components/pricing/ChangePlan'
import Payment from '@/components/pages/Payment'
import Verification from '@/components/account/Verification'
import AccountInputs from '@/components/account/AccountInputs'
import MailVerify from '@/components/account/MailVerify'
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    hashbang: false,
    routes: [
        {
            path: '/',
            name: 'Master',
            redirect: '/signup',
            component: Overview
        },
        {
            path: '/change-plan',
            name: 'ChangePlan',
            component: ChangePlan,
            props (route) {
                return route.query || {}
            }
        },
        {
            path: '/payment',
            name: 'Payment',
            component: Payment,
            props (route) {
                return route.query || {}
            }
        },
        {
            path: '/signup',
            name: 'SignUp',
            component: Signup
        },
        {
            path: '/signin',
            name: 'SignIn',
            component: Signin
        },
        {
            path: '/mail-verify',
            name: 'MailVerify',
            component: Verification
        },
        {
            path: '/resend',
            name: 'Resend',
            component: AccountInputs
        },
        {
            path: '/set-password',
            name: 'SetPassword',
            component: AccountInputs,
            props (route) {
                return route.query || {}
            }
        },
        {
            path: '/verify',
            name: 'Verify',
            component: MailVerify,
            props (route) {
                return route.query || {}
            }
        }
    ]
})

export default router
