<template>
<div class="trow">
    <div class="tcell">{{data.title}}</div>
    <div class="tcell">
    <div :class="data.checked ? 'checkmark' : 'crossmark'">
            <div class="check-icon">
                <span class="icon-line line-tip"></span>
                <span class="icon-line line-long"></span>
                <div class="icon-circle"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'trow',
    props: ['data']
}
</script>

<style lang="scss" scoped>
.trow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    &:first-of-type {
        margin-top: 0;
    }

    .tcell {
        display: flex;
        font-size: 16px;
        line-height: 22px;
        color: #888888;

        svg {
        transform: scale(0.8);
        }
    }
}
.checkmark {
    width: 32px;
    height: 32px;

    transform: scale(.8);

    .check-icon {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;

        .icon-line {
        height: 2px;
        background-color: color(bg, green);
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
            top: 18px;
            left: 7px;
            width: 8.5px;
            transform: rotate(45deg);
        }

        &.line-long {
            top: 16px;
            right: 5px;
            width: 16px;
            transform: rotate(-45deg);
        }
        }

        .icon-circle {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-sizing: content-box;
        background: rgba(color(bg, green), 0.2);
        }
    }
}

.crossmark {
    width: 32px;
    height: 32px;

    transform: scale(.8);

    .check-icon {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;

        .icon-line {
        height: 2px;
        background-color: color(bg, input-gray);
        display: block;
        border-radius: 2px;
        position: absolute;
        z-index: 10;

        &.line-tip {
            top: 15px;
            right: 6px;
            width: 20px;
            transform: rotate(-45deg);
        }

        &.line-long {
            top: 15px;
            right: 6px;
            width: 20px;
            transform: rotate(45deg);
        }
        }

        .icon-circle {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        box-sizing: content-box;
        background: color(bg, light);
        }
    }
}

</style>
