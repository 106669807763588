<template>
<div :class="[data.selected ? 'prices-card selected' : 'prices-card', data.id]" @click="$emit('selectCard', data.id)">
    <div class="card-header">
        <img v-if="data.id === 'basic'" src="/img/sources/Exchange.svg" alt="">
        <img v-if="data.id === 'business'" src="/img/sources/Microsoft365.svg" alt="">
        <img v-if="data.id === 'premium'" src="/img/sources/Hybrid.svg" alt="">
        <h2 class="title">{{data.label}}</h2>
        <!-- <h2 class="title">{{data.name}}</h2> -->
        <!-- <button class="btn btn-pricing" @click="fullPricing">See full pricing <i :class="$font_icons['chevron-right-s']" /></button> -->
        <div class="enterprise-price">
          <span class="from_to">{{data.enterprise[price].from}}-{{data.enterprise[price].to}} users</span>
          <!-- <br> -->
          <p class="price">{{data.enterprise[price].price}} <span v-if="currency==='eur'">€</span><span v-else>DKK</span></p>
        </div>
        <button v-if="showButton" class="btn btn-pricing" @click="fullPricing">{{$t('cards')}} <i :class="$font_icons['chevron-right-s']" /></button>
    </div>
    <div class="card-body">
        <div class="table">
            <trow
                v-for="trow in data.trows"
                v-bind:key="trow.id"
                v-bind:data="trow"
            ></trow>
        </div>
    </div>
</div>
</template>

<script>
import Trow from '@/components/pricing/Trow'

export default {
    name: 'changeplan',
    components: {
        Trow
    },
    props: ['data', 'price', 'prices', 'showButton', 'currency'],
    methods: {
        fullPricing () {
            this.$emit('fullPricing')
        }
    }
}
</script>

<style lang="scss" scoped>
.prices-card {
  width: 100%;
  max-width: 320px;
  margin: 0 4px;
  border-radius: $radius-lg;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.1));
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;

  .card-header {
    position: relative;
    padding: 30px;
    color: white;
    text-align: center;
    overflow: hidden;
    border: none;
    transition: 0.3s ease-in-out;

    border-top-left-radius: $radius-lg;
    border-top-right-radius: $radius-lg;

    &::before {
      position: absolute;
      top: -100px;
      left: -40px;
      transform: rotate(-2deg);
      content: "";
      display: block;
      width: 480px;
      height: 240px;
      border-radius: 50%;
      background-color: rgba(245, 247, 250, 0.1);
    }

    &::after {
      position: absolute;
      top: -80px;
      right: -80px;
      transform: rotate(16deg);
      content: "";
      display: block;
      width: 340px;
      height: 170px;
      border-radius: 50%;
      background-color: rgba(245, 247, 250, 0.1);
    }

    img {
      width: auto;
      height: 60px;
      margin: 10px auto;
    }

    .title {
      margin-top: 0;
      font-weight: 600;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
      text-transform: uppercase;
    }

    .price {
      margin-top: 8px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 28px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;

      span {
        font-size: 24px;
        opacity: 0.6;
      }
    }

    .enterprise-price {
      margin-top: 0px;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 28px;
      color: #ffffff;
      letter-spacing: 0;
      text-align: center;

      .from_to {
        margin-top: 0;
        font-size: 14px;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
      }

      .price {
        span {
          font-size: 24px;
          opacity: 0.6;
        }
      }
    }

    .per-user {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;

      span {
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
      }
    }

    .btn-pricing {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      padding: 4px 16px;
      border: 2px solid white;
      border-radius: 40px;
      color: white;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      background: transparent;
      cursor: pointer;
      opacity: 0.6;

      i {
        font-size: 12px;
        margin-left: 10px;
      }

      &:hover {
        background: white;
        color: color(typo, dark);
        opacity: 1;
      }
    }
  }

  &::before,
  &::after {
    content: '';
    transform: translateX(-50%) scale(0);
    transition: transform .8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &.basic {
    .card-header {
      background-image: linear-gradient(#7e5f45, #7e5f45);
    }

    &:hover,
    &.selected {
      filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.1));
    }

    &.selected {
      &::before {
        position: absolute;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        content: "";
        left: 50%;
        top: -28px;
        background: color(bg, green);
        transform: translateX(-50%) scale(1);
        z-index: 8888;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: -14px;
        display: inline-block;
        transform: rotate(45deg) translateX(-60%);
        height: 16px;
        width: 8px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
         z-index: 8888;
      }
      .card-header {
        background-image: radial-gradient(
          circle at 50% -8px,
          transparent 32px,
          #7e5f45 0
        );
        transition: 0.3s ease-in-out;
      }
      .card-body {
        box-shadow: inset 0 0 0 2px #7e5f45;
      }
    }
  }

  &.business {
    .card-header {
      background-image: linear-gradient(#b4b4b4, #b4b4b4);
    }

    &:hover,
    &.selected {
      filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.1));
    }
    &.selected {
      &::before {
        position: absolute;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        content: "";
        left: 50%;
        top: -28px;
        background: color(bg, green);
        transform: translateX(-50%);
        z-index: 8888;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: -14px;
        display: inline-block;
        transform: rotate(45deg) translateX(-60%);
        height: 16px;
        width: 8px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
         z-index: 8888;
      }
      .card-header {
        background-image: radial-gradient(
          circle at 50% -8px,
          transparent 32px,
          #b4b4b4 0
        );
        transition: 0.3s ease-in-out;
      }
      .card-body {
        box-shadow: inset 0 0 0 2px #b4b4b4;
      }
    }
  }

  &.premium {
    .card-header {
      background-image: linear-gradient(#e9ad54, #e9ad54);
    }

    &:hover,
    &.selected {
      filter: drop-shadow(0 0 24px rgba(0, 0, 0, 0.1));
    }
    &.selected {
      &::before {
        position: absolute;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        content: "";
        left: 50%;
        top: -28px;
        background: color(bg, green);
        transform: translateX(-50%);
        z-index: 8888;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: -14px;
        display: inline-block;
        transform: rotate(45deg) translateX(-60%);
        height: 16px;
        width: 8px;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        z-index: 8888;
      }
      .card-header {
        background-image: radial-gradient(
          circle at 50% -8px,
          transparent 32px,
          #e9ad54 0
        );
        transition: 0.3s ease-in-out;
      }
      .card-body {
        box-shadow: inset 0 0 0 2px #e9ad54;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
    background: #ffffff;

    border-bottom-left-radius: $radius-lg;
    border-bottom-right-radius: $radius-lg;

    .table {
      width: 100%;
      margin-bottom: 0;

      .trow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        &:first-of-type {
          margin-top: 0;
        }

        .tcell {
          display: flex;
          font-size: 16px;
          line-height: 22px;
          color: #888888;

          svg {
            transform: scale(0.64);
          }
        }
      }
    }
  }

  &:hover {
    transform: scale(1.02);
  }

  &.selected {
    position: relative;
    transform: scale(1.08);
    z-index: 9999;
  }
}
</style>
