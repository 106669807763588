<template>
<div class="pricing-table">
    <table>
        <span class="close-icon"><i :class="$font_icons['times-s']" @click="$emit('close')"/> </span>
        <thead>
            <tr>
                <th>Pricing table</th>
                <th v-for="(value, key) in columns" :key="key">{{value}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td># of users</td>
                <td>per month</td>
                <td>per month</td>
                <td>per month</td>
            </tr>
            <tr>
                <td></td>
                <td>billed annually</td>
                <td>billed annually</td>
                <td>billed annually</td>
            </tr>
            <tr v-for="(price, n) in prices[0].enterprise" :key="n">
                <td>{{prices[0].enterprise[n].from}}-{{prices[0].enterprise[n].to}}</td>
                <td @click="$emit('select', 'basic', n)">{{prices[0].enterprise[n].price}}<span v-if="currency==='eur'">€</span><span v-else>DKK</span></td>
                <td @click="$emit('select', 'business', n)">{{prices[1].enterprise[n].price}}<span v-if="currency==='eur'">€</span><span v-else>DKK</span></td>
                <td @click="$emit('select', 'premium', n)">{{prices[2].enterprise[n].price}}<span v-if="currency==='eur'">€</span><span v-else>DKK</span></td>
            </tr>
        </tbody>
    </table>
</div>
</template>
<script>
export default {
    props: ['prices', 'selected', 'currency'],
    data () {
        return {
            selectedPlan: '',
            columns: {
                basic: 'D&M for Exchange Online',
                business: 'D&M for Microsoft365',
                premiun: 'D&M for Hybrid'
            }
        }
    },
    mounted () {
    }
}
</script>
<style lang="scss" scoped>
.pricing-table {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  padding: 40px;
  background: rgba(51, 51, 51, 0.4);
  z-index: 999999;
  overflow: visible;

  table {
    position: relative;
    background: white;
    width: 100%;
    max-width: 1080px;
    border-radius: 8px;
    overflow: hidden;
    border-collapse: collapse;

    .close-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      color: white;

      &:hover {
        opacity: 0.8;
      }
    }

    thead {
      tr {
        th {
          width: calc(100% / 4);
          height: 64px;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          line-height: 24px;
          vertical-align: middle;
          text-transform: uppercase;

          &:first-child {
            font-weight: 700;
            font-size: 14px;
            color: #b7bac9;
            letter-spacing: 0;
            line-height: 40px;
          }

          &:nth-child(2) {
            background: color(bg, bronze);
          }
          &:nth-child(3) {
            background: color(bg, silver);
          }
          &:nth-child(4) {
            background: color(bg, gold);
          }
        }
      }
    }
    tbody {
      tr {
        border: 1px solid transparent;

        td {
          font-weight: 700;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          line-height: 32px;

          &:not(:last-child) {
            border-right: $border-light;
          }
          &:not(:first-child) {
            text-align: center;
          }
          &:first-child {
            padding-left: 30px;
            font-weight: 600;
            font-size: 14px;
            color: #888888;
            text-align: left;
            line-height: 36px;
          }
          &:hover {
            background-color: color(bg, green);
            color: white;
          }
        }

        &:first-child {
          td {
            font-weight: 600;
            font-size: 14px;
            color: #888888;
            line-height: 32px;
            border: none;

            &:hover {
              background: none;
            }
          }
        }

        &:nth-child(2) {
          td {
            font-size: 10px;
            color: #a7b8d3;
            line-height: 18px;

            &:hover {
              background: none;
            }
          }
        }

        &:nth-child(odd) {
          background: color(bg, light);
        }

        &:last-child {
          td {
            padding-bottom: 10px;
          }
        }

        &:hover:not(:first-child, :nth-child(2)) {
          td {
            border-top: 1px solid color(bg, green);
            border-bottom: 1px solid color(bg, green);
          }
        }
      }
    }
  }
}
</style>
