import BaseApi from './BaseApi'

class Signup extends BaseApi {
    endpoint = '/customer'

    registerCustomer = (data) => this.axiosInstance.post(`${this.endpoint}/validate`, data)
    customerEmail = (data) => this.axiosInstance.post(`${this.endpoint}/email`, data)
    customerVerify = (data) => this.axiosInstance.get(`${this.endpoint}/verify?uuid=${data}`)
    getCustomer = (data) => this.axiosInstance.post(`${this.endpoint}`, data)
    applyCoupon = (data) => this.axiosInstance.post(`${this.endpoint}/coupon`, data)
    getServiceAgreement = (data) => this.axiosInstance.post(`${this.endpoint}/service_agreement`, data)
}
export default new Signup()
