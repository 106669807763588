<template>
    <div class="free-screen" :class="$i18n.locale === 'dk' ? 'dk' : ''">
        <img
            src="../../../public/img/Dashboard-Privacy_Monitor.svg"
            alt="exchange_illustration"
            class="relv-illustration"
        />
        <h2 class="relv-title">
            {{ $t("free.title") }}
        </h2>
        <p class="relv-subtitle" v-html="$t('free.message')"></p>
        <a href="https://signup.dataandmore.com" class="btn-try"
            >{{ $t("plans") }}
            <span class="button__icon-wrapper">
                <svg
                    width="10"
                    class="button__icon-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 15"
                >
                    <path
                        fill="currentColor"
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    ></path>
                </svg>
                <svg
                    class="button__icon-svg button__icon-svg--copy"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    fill="none"
                    viewBox="0 0 14 15"
                >
                    <path
                        fill="currentColor"
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                    ></path>
                </svg>
            </span>
        </a>
    </div>
</template>

<script>
export default {
    methods: {
        fullPricing() {
            this.$emit('fullPricing')
        }
    }
}
</script>

<style lang="scss" scoped>
.free-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #0b5f79;
    border-radius: 32px;
    overflow: hidden;

    --card-color: #0b5f79;
    --pillar-size: 36px;
    --big-circle-r: 32px;
    --circle-r: 32px;
    --circle-d: calc(var(--circle-r) * 2);
    --circles-space: calc(var(--big-circle-r) + var(--pillar-size));
    --circle-extend-inset: calc(var(--circle-r) + var(--circles-space));

    background:
    /* Big Circle Cutout */ radial-gradient(
                circle at 100% 0%,
                transparent var(--big-circle-r),
                var(--card-color) calc(var(--big-circle-r))
            )
            0px var(--pillar-size) / calc(100% - var(--pillar-size) - 130px) 10%
            no-repeat no-repeat,
        /* Top Circle  */
            radial-gradient(
                circle closest-side,
                var(--card-color) 100%,
                transparent 100%
            )
            calc(100% - var(--circles-space) - 130px) 0 / var(--circle-d)
            var(--circle-d) no-repeat no-repeat,
        /* Right Circle */
            radial-gradient(
                circle closest-side,
                var(--card-color) 100%,
                transparent calc(100% + 0px)
            )
            100% var(--circles-space) / var(--circle-d) var(--circle-d)
            no-repeat no-repeat,
        /* Top Circle Extend */
            linear-gradient(
                0deg,
                var(--card-color) 100%,
                var(--card-color) 100%
            )
            0px var(--circle-r) / calc(0px - var(--circles-space))
            calc(100% - var(--circle-r)) no-repeat no-repeat,
        /* Right Gap fill Pillar */
            linear-gradient(
                0deg,
                var(--card-color) 100%,
                var(--card-color) 100%
            )
            0px 0px / calc(100% - var(--circle-extend-inset) - 130px)
            var(--circle-d) no-repeat no-repeat,
        /* Right Circle Extend  */
            linear-gradient(
                0deg,
                var(--card-color) 100%,
                var(--card-color) 100%
            )
            100% var(--circle-extend-inset) / var(--circle-d) 100% no-repeat
            no-repeat,
        /* Right Gap fill Pillar */
            linear-gradient(
                0deg,
                var(--card-color) 100%,
                var(--card-color) 100%
            )
            0 var(--circles-space) / calc(100% - var(--circle-r)) 100% no-repeat
            no-repeat;

    &.dk {
        background:
    /* Big Circle Cutout */ radial-gradient(
                    circle at 100% 0%,
                    transparent var(--big-circle-r),
                    var(--card-color) calc(var(--big-circle-r))
                )
                0px var(--pillar-size) / calc(100% - var(--pillar-size) - 210px)
                10% no-repeat no-repeat,
            /* Top Circle  */
                radial-gradient(
                    circle closest-side,
                    var(--card-color) 100%,
                    transparent 100%
                )
                calc(100% - var(--circles-space) - 210px) 0 / var(--circle-d)
                var(--circle-d) no-repeat no-repeat,
            /* Right Circle */
                radial-gradient(
                    circle closest-side,
                    var(--card-color) 100%,
                    transparent calc(100% + 0px)
                )
                100% var(--circles-space) / var(--circle-d) var(--circle-d)
                no-repeat no-repeat,
            /* Top Circle Extend */
                linear-gradient(
                    0deg,
                    var(--card-color) 100%,
                    var(--card-color) 100%
                )
                0px var(--circle-r) / calc(0px - var(--circles-space))
                calc(100% - var(--circle-r)) no-repeat no-repeat,
            /* Right Gap fill Pillar */
                linear-gradient(
                    0deg,
                    var(--card-color) 100%,
                    var(--card-color) 100%
                )
                0px 0px / calc(100% - var(--circle-extend-inset) - 210px)
                var(--circle-d) no-repeat no-repeat,
            /* Right Circle Extend  */
                linear-gradient(
                    0deg,
                    var(--card-color) 100%,
                    var(--card-color) 100%
                )
                100% var(--circle-extend-inset) / var(--circle-d) 100% no-repeat
                no-repeat,
            /* Right Gap fill Pillar */
                linear-gradient(
                    0deg,
                    var(--card-color) 100%,
                    var(--card-color) 100%
                )
                0 var(--circles-space) / calc(100% - var(--circle-r)) 100%
                no-repeat no-repeat;
    }

    &::before {
        content: url(/img/bg_logo.svg);
        position: absolute;
        left: -50%;
    }

    .relv-title {
        font-weight: 600;
        color: #ffffff !important;
        line-height: 40px;
    }
    .relv-subtitle {
        color: rgba(255, 255, 255, 0.8) !important;

        b {
            color: rgba(255, 255, 255, 1) !important;
        }
    }

    .btn-try {
        position: absolute;
        top: 10px;
        right: 12px;

        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 16px;
        background-color: color(bg, green);
        font-weight: 600;
        font-size: 18px;
        color: #ffffff;
        line-height: 24px;
        border-radius: 32px;
        transition: all 0.3s ease-in-out;

        .button__icon-wrapper {
            flex-shrink: 0;
            width: 25px;
            height: 25px;
            position: relative;
            color: color(bg, green);
            background-color: #fff;
            border-radius: 50%;
            display: grid;
            place-items: center;
            overflow: hidden;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            .button__icon-svg--copy {
                position: absolute;
                transform: translate(-150%, 150%);
            }
        }

        &:hover {
            cursor: pointer;
            background-color: color(typo, dark);
            .button__icon-wrapper {
                color: color(typo, dark);

                .button__icon-svg {
                    &:first-child {
                        transition: transform 0.2s ease-in-out;
                        transform: translate(150%, -150%);
                    }
                }

                .button__icon-svg--copy {
                    transition: transform 0.4s ease-in-out 0.1s;
                    transform: translate(0);

                    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
            }
        }
    }
}
</style>
