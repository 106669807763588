<template>
  <div class="demo-screen">
    <div class="relv-plans">
      <div class="plan">
        <h3>free</h3>
        <p><strong>NO</strong> monthly fees</p>
        <p><strong>5</strong> users <strong>FREE</strong></p>
      </div>
      <div class="devider"></div>
      <div class="plan">
        <h3>paid</h3>
        <p><strong>UNLIMITED</strong> users</p>
        <div class="btn-ghost" @click="fullPricing">View Pricing</div>
      </div>
    </div>
    <img
      src="../../../public/img/login/exchange_illustration.png"
      alt="exchange_illustration"
      class="relv-illustration"
    />
    <h2 class="relv-title">
      {{ $t("demo.title") }}
    </h2>
    <p class="relv-subtitle">
      {{ $t("demo.message") }}
    </p>
  </div>
</template>

<script>
export default {
    methods: {
        fullPricing () {
            this.$emit('fullPricing')
        }
    }
}
</script>

<style lang="scss" scoped>
  .demo-screen {
    display: contents;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 32px;
    overflow: hidden;
    background-image: linear-gradient(270deg, #EFF1F7 23%, #e4e9f1 100%);
  }
</style>
