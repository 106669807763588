<template>
<div class="wrapper">
  <div class="panel">
    <div class="panel-left">
      <header>
          <img
              class="relv-logo"
              src="../../../public/img/Data_and_More_Logo_Dark.png"
          />
          <h2 class="relv-title">Successful email verification</h2>
          <p class="relv-subtitle">You have verified your email successfully and now you can go and proceed with Your payment.</p>

          <h2 class="relv-title" v-if="path==='/set-password'">Set your password</h2>
            <div class="user-info" v-if="path==='/set-password'">
                <h3 class="summary-title">Account information</h3>
                <div>
                    <span class="info-name">Company name:</span>
                    <span>{{customerData.company_name.value}}</span>
                </div>
                <div>
                    <span class="info-name">Full name:</span>
                    <span>{{customerData.full_name.value}}</span>
                </div>
                <div>
                    <span class="info-name">Workspace:</span>
                    <span>{{customerData.workspace.value}}</span>
                </div>
                <div>
                    <span class="info-name">Email:</span>
                    <span>{{customerData.email.value}}</span>
                </div>
                <div>
                    <span class="info-name">VAT number:</span>
                    <span>{{customerData.country_code.value + ' ' + customerData.vat_number.value}}</span>
                </div>
            </div>
          <h2 class="relv-title" v-if="path==='/resed'">Enter email</h2>
          <p class="relv-subtitle" v-if="path==='/resend'">
              Please enter your email.
          </p>
      </header>
      <div class="relv-form">
            <div class="form-group" v-for="(field, key) in formFields" :key="key" :class="key">
                <input-field v-if="field.type==='input'"
                    @reset="resetFields(key)"
                    @change="inputChange(key, $event)"
                    :data="field"
                />
                <input-select v-else-if="field.type==='selectlist'"
                  @reset="resetFields(key)"
                  @change="selectChange(key, $event)"
                  :data="field"
                />
                <div v-else-if="field.type==='inputtext'" class="vat-number">
                    <span class="country-code">{{formFields.country_code.value}}</span><input-field
                    @reset="resetFields(key)"
                    @change="inputChange(key, $event)"
                    :data="field"
                />
                </div>
            </div>
            <button class="relv-btn" @click.prevent="sendEmail" v-if="path==='/resend'">
                Send email
            </button>
            <button class="relv-btn" @click.prevent="setPassword" v-if="path==='/set-password'">
                <div v-if="loading" class="spinner" id="spinner"></div>
                <span v-else>Continue</span>
            </button>
        </div>
      <footer>
          <span>© {{ new Date().getFullYear() }}, </span>
          <a href="https://dataandmore.com/" target="_blank">
              Data &amp; More
          </a>
      </footer>
    </div>
    <div class="panel-right" v-if="showTable">
      <Table
          :prices="plans"
          :currency="currency"
          @close="showTable=false"
          @select="showTable=false"
      />
    </div>
    <div class="panel-right center" v-else-if="customerData.demo_account.value">
        <DemoScreen
          @fullPricing="showTable=true"
        />
      </div>
    <div class="panel-right" v-else>
        <img src="../../../public/img/login/plans.png" alt="" class="relv-illustration">
        <h2 class="relv-title">Plans for every business size</h2>
        <p class="relv-subtitle">We’ve created plans for every size of business and every number of users you have. If you find out that you need any other plan you can always choose different one at any time.</p>
    </div>
  </div>
</div>
</template>
<script>
import InputField from '@/components/forms/InputField'
import { mapState } from 'vuex'
import signupApi from '@/api/signup.js'
import router from '@/router'
import InputSelect from '../forms/InputSelect.vue'
import DemoScreen from '@/components/account/DemoScreen'
import Table from '@/components/pricing/Table'

export default {
    props: ['uuid'],
    components: {
        InputField,
        InputSelect,
        DemoScreen,
        Table
    },
    data () {
        return {
            path: this.$route.path,
            loading: false,
            passwordFields: {
                password: {
                    name: 'Password *',
                    type: 'input',
                    class: 'fa fa-lock form-control-feedback text-muted',
                    input_type: 'password',
                    value: '',
                    regex: /^(?!(.{0,10}|[^a-z]*|[^A-Z]*|[^0-9]*|[a-zA-Z0-9]*)$)/,
                    wrongFormatMessage: 'Must contain a lowercase and uppercase letter, a number, a special character and be at least 10 characters long!',
                    error: false,
                    success: false,
                    input_message: '',
                    empty: '',
                    placeholder: 'Password',
                    confirm_password: 'repeat_password'
                },
                repeat_password: {
                    name: 'Confirm Password *',
                    type: 'input',
                    class: 'fa fa-lock form-control-feedback text-muted',
                    input_type: 'password',
                    value: '',
                    error: false,
                    success: false,
                    input_message: '',
                    empty: '',
                    placeholder: 'Confirm Password'
                }
            },
            resend: {
                email: {
                    name: 'Email *',
                    type: 'input',
                    class: 'fa fa-envelope form-control-feedback text-muted',
                    input_type: 'email',
                    value: '',
                    regex: /\S+@\S+\.\S+/,
                    wrongFormatMessage: 'Wrong email format!',
                    error: false,
                    success: false,
                    input_message: '',
                    empty: '',
                    placeholder: 'Email'
                },
                workspace: {
                    name: 'Workspace *',
                    type: 'input',
                    class: 'fa fa-building form-control-feedback text-muted',
                    input_type: 'text',
                    regex: /^[a-z0-9_-]*$/,
                    wrongFormatMessage: 'Wrong format! Workspace can contain letters (a-z), digits(0-9) and underscore (_)!',
                    value: '',
                    error: false,
                    success: false,
                    input_message: '',
                    empty: '',
                    min_length: 3,
                    placeholder: 'Workspace'
                }
            },
            formFields: {},
            showTable: false
        }
    },
    computed: mapState({
        customerData: (state) => state.signup.customerData,
        plans: (state) => state.pricing.plans,
        currency: (state) => state.pricing.currency
    }),
    methods: {
        inputChange (key, value) {
            this.formFields[key].value = value
        },
        selectChange (key, value) {
            this.formFields[key].value = value
        },
        sendEmail () {
            this.fieldValidate('email')
            if (this.formFields.email.error) {
                return false
            }
            let data = {
                email: this.formFields.email.value,
                workspace: this.formFields.workspace.value
            }
            signupApi.customerEmail(data).then((response) => {
                router.push({ name: 'MailVerify' })
            }).catch(() => {
                this.formFields.email.input_message = 'User with this mail does not exists'
            })
        },
        setPassword () {
            Object.keys(this.formFields).forEach((key) =>
                this.fieldValidate(key)
            )
            const error = Object.values(this.formFields).find(
                (field) => field.error
            )
            if (error) {
                return false
            }
            let data = Object.keys(this.formFields).reduce((acc, curr) => {
                acc[curr] = this.formFields[curr].value
                return acc
            }, {})
            const reqData = {
                uuid: this.uuid,
                password: data.password,
                repeat_password: data.repeat_password
            }
            this.loading = true
            this.$store.dispatch('payment/callSuccess', { data: reqData }).then((response) => {
                this.loading = false
                localStorage.clear()
                window.open(`https://${response.data.url}`, '_self')
            }).catch(() => {
                this.formFields.password.input_message = 'Something went wrong try again!'
            })
        },
        setErrorMessage (field, message) {
            this.formFields[field].error = true
            this.formFields[field].input_message = message
        },
        resetFields (field) {
            this.formFields[field].error = false
            this.formFields[field].success = false
            this.formFields[field].input_message = ''
        },
        fieldValidate (fieldName) {
            let data = this.formFields[fieldName]
            if (data.empty !== null && data.empty === data.value) {
                data.input_message = 'Can\'t leave empty!'
                data.error = true
            } else if (data.min_length !== undefined && data.value.length <= data.min_length) {
                data.input_message = `${data.placeholder} must have more then ${data.min_length} characters!`
                data.error = true
            } else if (data.regex !== undefined) {
                let regex = data.regex
                if (!regex.test(data.value)) {
                    data.input_message = data.wrongFormatMessage
                    data.error = true
                }
            } else if (data.confirm_password !== undefined) {
                if (data.value !== this.formFields[data.confirm_password].value) {
                    data.input_message = 'Passwords do not match.'
                    data.error = true
                }
            }
        }
    },
    async mounted () {
        if (this.path === '/set-password') {
            signupApi.getCustomer({
                uuid: this.uuid
            }).then(response => {
                this.$store.commit('signup/setCustomerData', {
                    data: response.data
                })
            })
            this.formFields = this.passwordFields
        } else {
            this.formFields = this.resend
        }

        this.$store.dispatch('pricing/getPlans')
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .panel {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    overflow: hidden;

    @include respond-to("medium") {
      flex-direction: column;
    }

    .panel-left {
      overflow: hidden;

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      header {
        text-align: center;

        .relv-logo {
          height: 50px;
          margin-bottom: 0px;
        }
      }

      .relv-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        animation: fade-in-right 0.4s ease both;

        .relv-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 36px;
          border-radius: $radius;
          background-color: color(bg, green);
          color: white;
          font-size: 14px;
          line-height: 20px;
          border: none;
          outline: none;
          transition: 0.3s ease-in-out;

          i {
            margin-left: 10px;
          }

          &:hover {
            background-color: darken(#4154b8, 6);
            cursor: pointer;
          }

          &:disabled,
          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding-top: 20px;
        font-size: 14px;
        line-height: 20px;
        color: color(typo, gray);

        a {
          margin-left: 5px;
          font-weight: 600;
          color: color(typo, dark);
        }
      }
    }

    .panel-right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 40px;
      background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      .relv-illustration {
        max-width: 540px;
        height: auto;
        filter: drop-shadow(0 0 64px rgba(0, 0, 0, 0.2));
      }

      .relv-title,
      .relv-subtitle {
        padding: 0 30px;
      }

      .cards {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 64px;
      }
    }

    .relv-title {
      margin: 30px 0 10px;
      font-size: 20px;
      font-weight: 600;
      color: color(typo, dark);
      text-align: center;
      line-height: 38px;
    }

    .relv-subtitle {
      max-width: 420px;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 18px;
      color: color(typo, gray);
      letter-spacing: 0;
      text-align: center;

      b {
        font-weight: 600;
      }
    }

    .relv-small-text {
      display: inline;
      margin: 10px 0 0;
      font-size: 12px;
      color: color(typo, gray);
      text-align: center;
    }
  }
}
header {
  text-align: center;

  .relv-logo {
    height: 70px;
    margin-bottom: 30px;
  }

  .relv-title {
    margin: 30px 0 10px;
  }
  .relv-subtitle {
    max-width: 640px;
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 18px;
    color: color(typo, gray);
    letter-spacing: 0;
    text-align: center;

    b {
      font-weight: 600;
    }
  }
}
.relv-illustration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    max-width: 640px;
    margin-top: 24px;
    font-size: 14px;
    line-height: 18px;
    color: color(typo, gray);
    letter-spacing: 0;
    text-align: center;

    a {
      color: color(typo, link);
    }
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% + 60px);
  margin: 30px -30px;
  // border: $border-light;
  box-shadow: $shadow-sm, $shadow-lg;
  padding: 15px;
  border-radius: 14px;
  gap: 15px;

  h3 {
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 0;
    text-align: left;
    font-size: 16px;
  }

  div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 15px;

    span {
      display: inline-flex;
      font-size: 14px;

      &.info-name {
        color: color(typo, gray);
      }
    }
  }
}
.email {
  .input-field {
    .input-wrap {
      input {
        background-color: white;
        border: none;
      }
    }
  }
}
</style>
