import Vue from 'vue'
import FloatingVue from 'floating-vue'
import VueSanitize from 'vue-sanitize'
import App from './App.vue'
import router from './router'
import store from './store'
import fontIcons from './font_icons/FontIcons.js'
import Flags from './font_icons/Flags.js'
import i18n from './i18n'

Vue.config.productionTip = false
Object.defineProperty(Vue.prototype, '$font_icons', { value: fontIcons })
Object.defineProperty(Vue.prototype, '$flagsIcons', { value: Flags })
VueSanitize.defaults.allowedTags.push('img')
VueSanitize.defaults.allowedSchemes.push('data')

Vue.use(VueSanitize)
Vue.use(FloatingVue)

new Vue({
    router,
    store,
    i18n,
    Flags,
    render: h => h(App)
}).$mount('#app')
