<template>
<div class="wrapper">
    <div class="panel">
        <div class="panel-left">
            <header>
                <img
                    class="relv-logo"
                    src="../../../public/img/Data_and_More_Logo_Dark.png"
                />
                <h2 class="relv-title">Sign in to your account</h2>
                <p class="relv-subtitle">
                    See how <b>Data&More</b> detects and handles your <b>non-compliant data</b> with <b>Automated Data Compliance</b> algorithm.
                </p>
            </header>
            <div class="relv-form">
                <div class="form-group">

                </div>
                <button class="relv-btn">
                    Continue <i :class="$font_icons['arrow-right-s']" title="next" />
                </button>
            </div>
            <footer>
                <span>© {{ new Date().getFullYear() }}, </span>
                <a href="https://dataandmore.com/" target="_blank">
                    Data &amp; More
                </a>
            </footer>
        </div>
        <div class="panel-right">
            <img src="../../../public/img/login/plans.png" alt="" class="relv-illustration">
            <h2 class="relv-title">Plans for every business size</h2>
            <p class="relv-subtitle">We’ve created plans for every size of business and every number of users you have. If you find out that you need any other plan you can always choose different one at any time.</p>
        </div>
    </div>
</div>
</template>
<script>
export default {

}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .panel {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    overflow: hidden;

    @include respond-to("medium") {
      flex-direction: column;
    }

    .panel-left {
      overflow: hidden;

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      header {
        text-align: center;

        .relv-logo {
          height: 70px;
          margin-bottom: 30px;
        }
      }

      .relv-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        animation: fade-in-right 0.4s ease both;

        .relv-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 36px;
          border-radius: $radius;
          background-color: #4154b8;
          color: white;
          font-size: 14px;
          line-height: 20px;
          border: none;
          outline: none;
          transition: 0.3s ease-in-out;

          i {
            margin-left: 10px;
          }

          &:hover {
            background-color: darken(#4154b8, 6);
            cursor: pointer;
          }

          &:disabled,
          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding-top: 20px;
        font-size: 14px;
        line-height: 20px;
        color: color(typo, gray);

        a {
          margin-left: 5px;
          font-weight: 600;
          color: color(typo, dark);
        }
      }
    }

    .panel-right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 40px;
      background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      .relv-illustration {
        max-width: 540px;
        height: auto;
        filter: drop-shadow(0 0 64px rgba(0, 0, 0, 0.2));
      }

      .relv-title,
      .relv-subtitle {
        padding: 0 30px;
      }

      .cards {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 64px;
      }
    }

    .relv-title {
      margin: 30px 0 10px;
      font-size: 24px;
      font-weight: 600;
      color: color(typo, dark);
      text-align: center;
      line-height: 38px;
    }

    .relv-subtitle {
      max-width: 420px;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 18px;
      color: color(typo, gray);
      letter-spacing: 0;
      text-align: center;

      b {
        font-weight: 600;
      }
    }

    .relv-small-text {
      display: inline;
      margin: 10px 0 0;
      font-size: 12px;
      color: color(typo, gray);
      text-align: center;
    }
  }
}
</style>
