<template>
    <div class="summary-section">
        <h4 class="summary-title">Subsription Summary</h4>
        <div class="plan-section">
            <span class="plan-name_title">plan name</span>
            <span class="price_title">price</span>
            <span class="plan-name">{{card.label}} <span><router-link :to="{ path: '/change-plan', query: { uuid: uuid }}" class="change-plan">(Change Plan)</router-link></span></span>
            <span class="price"><span>Based on active acconts </span></span>
        </div>
        <div class="accounts-section">
            <span class="active-accounts_title">active accounts</span>
            <span class="active-accounts">
              <span @click="showAccountsList">{{card.enterprise[currentEnterprise].from}} - {{card.enterprise[currentEnterprise].to}}</span>
              <div class="accounts-list" v-if="accountsList">
                <ul v-click-outside="closeAccountList">
                  <li v-for="(price, index) in card.enterprise" :key="index"
                      @click="setEnterprise(index)"
                      :class="index===currentEnterprise ? 'active': ''">
                    {{price.from}} - {{price.to}}
                    <span>{{price.price.toFixed(2)}}<span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span></span>
                  </li>
                </ul>
              </div>
              <div class="change-numbers">
                <button :disabled="currentEnterprise===0" class="minus"  @click="decreaseEnterprise"><i :class="$font_icons['minus-s']" /></button>
                <button :disabled="currentEnterprise===card.enterprise.length-1" class="plus" @click="increaseEnterprise"><i :class="$font_icons['plus-s']" /></button>
              </div>
            </span>
            <span class="active-accounts_price">{{card.enterprise[currentEnterprise].price.toFixed(2)}}<span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span> <span>/ month</span></span>
        </div>
        <div class="period-section">
            <span class="contract-period_title">contract period <span v-if="lengths[length]">- Save {{lengths[length]*100}}%</span></span>
            <span class="contract-period">{{length}} Months <div class="change-numbers"><button class="minus" :disabled="length===12" @click="decreaseLength"><i :class="$font_icons['minus-s']" /></button><button :disabled="length===48" class="plus" @click="increaseLength"><i :class="$font_icons['plus-s']" /></button></div></span>
        </div>
        <div class="total-section" v-if="currency==='dkk'">
            <span class="total_title">Subtotal</span>
            <span class="total_price">{{totalPrice.toFixed(2)}} <span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span><span> / year</span></span>
            <span class="total_title vat">+ VAT 25% </span>
            <span class="total_price vat">{{(totalPrice * 25/100).toFixed(2)}} <span class="currency">DKK</span><span> / year</span></span>

            <span class="total_title vat" v-if="lengths[length]">-{{lengths[length]*100}}% <span>for <b>{{length}}</b> Months contract period</span></span>
            <span class="total_price vat" v-if="lengths[length]">-{{(totalPrice * lengths[length]).toFixed(2)}} <span class="currency">DKK</span><span> / year</span></span>

            <span v-if="coupon.type === 'once'" class="total_title vat">-{{coupon.percent_off}}% <span>with coupon (first year only)</span></span>
            <span v-else-if="coupon.type === 'forever'" class="total_title vat">-{{coupon.percent_off}}% <span>with coupon</span></span>
            <span v-if="coupon.percent_off" class="total_price vat">-{{(totalPriceWithVat(totalPrice) * (coupon.percent_off / 100)).toFixed(2)}} <span class="currency">DKK</span><span> / year</span></span>

            <div class="total-section_inner">
              <span class="total_title rest">First Year</span>
              <span class="total_price rest" v-if="coupon.percent_off">
                  {{(totalPriceWithVat(totalPrice) - totalPriceWithVat(totalPrice) * (coupon.percent_off / 100)).toFixed(2)}}<span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span> <span>/ year</span>
              </span>
              <span class="total_price rest" v-else>{{totalPriceWithVat(totalPrice)}}<span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span> <span>/ year</span></span>

              <span class="total_title rest">Rest of contract</span>
              <span class="total_price rest">
                  <span v-if="coupon.type === 'forever'">{{(totalPriceWithVat(totalPrice) - totalPriceWithVat(totalPrice) * (coupon.percent_off / 100)).toFixed(2)}}</span>
                  <span v-else class="price">{{totalPriceWithVat(totalPrice)}}</span>
                  <span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span> <span>/ year</span>
              </span>
            </div>
        </div>
        <div class="total-section" v-else>
            <span class="total_title">Total</span>
            <span class="total_price">{{totalPrice}} <span v-if="currency==='eur'" class="currency">€</span><span v-else class="currency">DKK</span> <span>/ year</span></span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ClickOutside from '@/directives/ClickOutside'

export default {
    props: ['prices', 'lengths', 'uuid', 'currency', 'coupon'],
    data () {
        return {
            regularUsers: 1,
            length: 12,
            currentEnterprise: 0,
            accountsList: false
        }
    },
    directives: {
        ClickOutside
    },
    computed: {
        regularpricePerUser () {
            return Number((this.card.regular.price_per_user * this.regularUsers).toFixed(2))
        },
        totalPrice () {
            let total = this.card.enterprise[this.currentEnterprise].price * 12
            if (this.lengths[this.length]) {
                total = total - total * this.lengths[this.length]
            }
            if (this.currency === 'dkk') {
                this.$emit('invoice', Number((total + total * 25 / 100).toFixed(2)))
            } else {
                this.$emit('invoice', Number((total).toFixed(2)))
            }

            return Number((total).toFixed(2))
        },
        ...mapState({
            card: (state) => state.pricing.card,
            enterprise: (state) => state.pricing.enterprisePlan
        })
    },
    methods: {
        decreaseRegular () {
            this.regularUsers--
            this.$store.commit('payment/setUsers', { users: this.regularUsers })
        },
        totalPriceWithVat (totalPrice) {
            return (totalPrice * 1.25).toFixed(2)
        },
        increaseRegular () {
            this.regularUsers++
            this.$store.commit('payment/setUsers', { users: this.regularUsers })
        },
        decreaseLength () {
            this.length = this.length - 12
            this.$store.commit('payment/setLength', { length: this.length })
        },
        increaseLength () {
            this.length = this.length + 12
            this.$store.commit('payment/setLength', { length: this.length })
        },
        decreaseEnterprise () {
            this.currentEnterprise--
            this.$store.commit('payment/setUsers', { users: this.card.enterprise[this.currentEnterprise].to })
        },
        increaseEnterprise () {
            this.currentEnterprise++
            this.$store.commit('payment/setUsers', { users: this.card.enterprise[this.currentEnterprise].to })
        },
        setEnterprise (index) {
            this.currentEnterprise = index
            this.accountsList = false
            this.$store.commit('payment/setUsers', { users: this.card.enterprise[this.currentEnterprise].to })
        },
        showAccountsList () {
            this.accountsList = !this.accountsList
        },
        closeAccountList () {
            this.accountsList = false
        }
    },
    mounted () {
        if (this.enterprise) {
            this.currentEnterprise = this.enterprise
        }
        this.$store.commit('payment/setPlan', { length: this.length, users: this.card.enterprise[this.currentEnterprise].to })
    }
}
</script>

<style lang="scss" scoped>
.summary-section {
  margin-bottom: 0 !important;
  width: 100%;
  box-shadow: -5px 5px 30px rgb(0 0 0 / 10%);
  box-shadow:  -10px -10px 30px rgba(202, 202, 202, 0.4),
             10px 10px 30px #f6f6f6;
  padding: 20px;
  border-radius: 14px;

  .summary-title {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    color: #333333;
    text-transform: uppercase;
  }

  .plan-name_title,
  .price_title,
  .active-accounts_title,
  .contract-period_title {
    font-weight: 600;
    font-size: 14px;
    color: #b7bac9;
    text-transform: uppercase;
    line-height: 24px;
  }

  .price_title {
    text-align: right;
  }

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    span {
      width: 50%;
    }
    button {
      width: 50%;
    }
  }

  .plan-name,
  .active-accounts,
  .contract-period {
    font-weight: 600;
    font-size: 18px;
    color: #444447;
    line-height: 24px;

    .change-plan {
      font-weight: normal;
      font-size: 14px;
      color: color(typo, link);
      line-height: 24px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    input {
      padding: 0;
      border: none;
      font-weight: 600;
      font-size: 18px;
      color: #444447;
      line-height: 24px;
      width: 65px;
      &:focus {
        outline: none;
      }
    }
  }

  .price,
  .active-accounts_price,
  .contract-period_time {
    font-weight: 700;
    font-size: 18px;
    color: #444447;
    letter-spacing: 0;
    text-align: right;
    line-height: 24px;

    span {
      font-weight: normal;
      font-size: 14px;
      color: color(typo, gray);
      line-height: 24px;
    }

    .currency {
      font-weight: 700;
      font-size: 18px;
      color: #444447;
      letter-spacing: 0;
      text-align: right;
      line-height: 24px;
    }
  }

  .saving {
    margin-top: 5px;
    font-weight: 700;
    font-size: 18px;
    color: color(typo, green);
    letter-spacing: 0;
    text-align: right;
    line-height: 24px;
  }

  .contract-period_time {
    i {
      margin-right: 10px;
      font-size: 14px;
      color: color(bg, input-gray);
    }
  }

  .total-section {
    margin-top: 20px;
    margin-bottom: 0;
    padding: 10px 20px;
    border-radius: 10px;
    background: hsl(216, 33%, 98%);
    border: 1px solid hsl(216, 33%, 96%);
    overflow: hidden;

    .total_title {
      font-weight: 600;
      font-size: 20px;
      color: #444447;
      letter-spacing: 0;
      line-height: 40px;

      &.vat {
        font-size: 16px;
        line-height: 24px;

        span {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: color(typo, gray);
        }
      }
    }
    .total_price {
      font-weight: 900;
      font-size: 22px;
      color: #444447;
      letter-spacing: 0;
      text-align: right;
      line-height: 40px;

      span {
        font-weight: normal;
        font-size: 16px;
        color: color(typo, gray);
        line-height: 40px;
      }

      .currency {
        font-weight: 700;
        font-size: 18px;
        color: #444447;
        letter-spacing: 0;
        text-align: right;
        line-height: 24px;
      }

      &.vat {
        font-size: 16px;
        line-height: 24px;

        .currency,
        span {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .total-section_inner {
      width: calc(100% + 40px);
      margin: 20px -20px -10px -20px;
      padding: 10px 20px;
      background: hsl(216, 33%, 94%);
      opacity: 0.8;

      .total_title {
        font-size: 16px;

        &.rest {
          font-size: 14px;
          line-height: 24px;
        }
      }

      .total_price {
        font-size: 18px;

        &.rest {
          font-size: 14px;
          line-height: 24px;

          .price {
            font-weight: 600;
            color: hsl(240, 2%, 40%);
            text-align: right;
            font-size: 14px;
            line-height: 24px;
          }

          .currency {
            margin-left: 4px;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: hsl(240, 2%, 40%);
          }

          span {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .contract-period_title,
  .active-accounts_title {
    width: 100%;

    span {
      font-weight: 700;
      font-size: 14px;
      color: color(typo, green);
      line-height: 24px;
      text-transform: none;
    }
  }

  .change-numbers {
    display: inline-flex;
    align-items: center;
    width: 48px;
    height: 24px;
    /* margin-right: 60px; */
    margin-bottom: 0;
    float: right;
    border-radius: 4px;
    color: color(typo, link);
    overflow: hidden;

    button {
      border: none;
      color: color(typo, link);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      background-color: color(bg, light);
      font-size: 10px;
      cursor: pointer;

      &:hover {
        background-color: darken(color(bg, light), 2);
      }

      &:disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  .active-accounts {
    position: relative;

    span {
      cursor: pointer;
    }

    .accounts-list {
      width: 220px;
      position: absolute;
      left: -10px;
      padding: 5px;
      margin: 0;
      background: #ffffff;
      box-shadow: 0 0 16px 0 rgba(51, 51, 51, 0.1);
      font-size: 14px;
      line-height: 24px;

      ul {
        margin: 0;

        li {
          display: inline-flex;
          justify-content: space-between;
          width: 100%;
          padding: 0 5px;
          padding-right: 30px;
          font-weight: normal;
          border-radius: 4px;
          cursor: pointer;

          span {
            display: inline-flex;
            justify-content: flex-end;
            margin-left: auto;
            font-weight: 600;
          }

          &:hover {
            background: color(bg, light);
            color: color(typo, dark);
          }

          &.active {
            background: color(bg, green);
            color: white;

            &::after {
              content: "\f00c";
              position: absolute;
              right: 10px;
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              font-size: 8px;
              line-height: 1;
              float: right;
              padding: 4px;
              margin: 5px 0;
              background: #ffffff4d;
              border-radius: $radius-r;
              animation: scale-up-center 0.4s
                cubic-bezier(0.175, 0.885, 0.59, 1.65) both;
            }
          }
        }
      }
    }
  }

  .plan-section,
  .accounts-section,
  .period-section,
  .total-section {
    /* width: 100%;
    border: 1px solid #eeeeee;
    box-shadow: 0 0 1px rgb(0 0 0 / 20%);
    padding: 15px;
    border-radius: 14px; */
  }
}
</style>
