import Vue from 'vue'
import Vuex from 'vuex'

import signup from './modules/signup'
import pricing from './modules/pricing'
import payment from './modules/payment'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        signup,
        pricing,
        payment
    },
    strict: debug
})
