<template>
  <div>

  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
    name: 'overview',
    data () {
        return {
            pageTitle: 'Overview'
        }
    },
    methods: {
        ...mapActions([
            'setPageTitle'
        ])
    },
    beforeMount () {
        this.setPageTitle(this.pageTitle)
    }
}
</script>
<style lang="scss">
</style>
