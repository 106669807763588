<template>
    <actions
        :actions="languages"
        :selected="selected"
        mode="selectlist"
        @change="changeLanguage"
        class="language-dropdown"
    />
</template>

<script>
import Actions from '@/components/dropdown/Actions.vue'

export default {
    components: {
        Actions
    },
    data () {
        let languages = {
            en: {
                key: 'en',
                name: 'English',
                img: this.$flagsIcons.flags_lang['English']
            },
            dk: {
                key: 'dk',
                name: 'Danish',
                img: this.$flagsIcons.flags_lang['Danish']
            },
            de: {
                key: 'de',
                name: 'German',
                img: this.$flagsIcons.flags_lang['German']
            },
            pt: {
                key: 'pt',
                name: 'Portuguese',
                img: this.$flagsIcons.flags_lang['Portuguese']
            },
            sv: {
                key: 'sv',
                name: 'Swedish',
                img: this.$flagsIcons.flags_lang['Swedish']
            },
            no: {
                key: 'no',
                name: 'Norwegian',
                img: this.$flagsIcons.flags_lang['Norwegian']
            },
            fr: {
                key: 'fr',
                name: 'French',
                img: this.$flagsIcons.flags_lang['French']
            },
            fi: {
                key: 'fi',
                name: 'Finnish',
                img: this.$flagsIcons.flags_lang['Finnish']
            },
            es: {
                key: 'es',
                name: 'Spanish',
                img: this.$flagsIcons.flags_lang['Spanish']
            },
            it: {
                key: 'it',
                name: 'Italian',
                img: this.$flagsIcons.flags_lang['Italian']
            },
            pl: {
                key: 'pl',
                name: 'Polish',
                img: this.$flagsIcons.flags_lang['Polish']
            },
            nl: {
                key: 'nl',
                name: 'Dutch',
                img: this.$flagsIcons.flags_lang['Dutch']
            }
        }
        let selected = languages[this.$i18n.locale]
        return {
            selected,
            languages
        }
    },
    methods: {
        changeLanguage (action) {
            this.selected = action
            this.$i18n.locale = action.key
            localStorage.setItem('LOCALE', action.key)
        }
    }
}
</script>

<style>
.btn-more {
    vertical-align: middle;
}
</style>
