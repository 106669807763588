/* eslint-disable no-tabs */
<template>
    <div class="full_page-loader">
        <div class="hexa-loader">
            <div class="hexa-left">
                <div class="hexa_one">
                    <div class="box-1"></div>
                    <div class="box-2"></div>
                    <div class="box-3"></div>
                </div>
                <div class="hexa_two">
                    <div class="box-4"></div>
                    <div class="box-5"></div>
                    <div class="box-6"></div>
                </div>
                <div class="hexa_three">
                    <div class="inner-loader">
                        <div class="row">
                            <div class="arrow up outer outer-18"></div>
                            <div class="arrow down outer outer-17"></div>
                            <div class="arrow up outer outer-16"></div>
                            <div class="arrow down outer outer-15"></div>
                            <div class="arrow up outer outer-14"></div>
                        </div>
                        <div class="row">
                            <div class="arrow up outer outer-1"></div>
                            <div class="arrow down outer outer-2"></div>
                            <div class="arrow up inner inner-6"></div>
                            <div class="arrow down inner inner-5"></div>
                            <div class="arrow up inner inner-4"></div>
                            <div class="arrow down outer outer-13"></div>
                            <div class="arrow up outer outer-12"></div>
                        </div>
                        <div class="row">
                            <div class="arrow down outer outer-3"></div>
                            <div class="arrow up outer outer-4"></div>
                            <div class="arrow down inner inner-1"></div>
                            <div class="arrow up inner inner-2"></div>
                            <div class="arrow down inner inner-3"></div>
                            <div class="arrow up outer outer-11"></div>
                            <div class="arrow down outer outer-10"></div>
                        </div>
                        <div class="row">
                            <div class="arrow down outer outer-5"></div>
                            <div class="arrow up outer outer-6"></div>
                            <div class="arrow down outer outer-7"></div>
                            <div class="arrow up outer outer-8"></div>
                            <div class="arrow down outer outer-9"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hexa-right">
                <div class="hexa_one">
                    <div class="box-1"></div>
                    <div class="box-2"></div>
                    <div class="box-3"></div>
                </div>
                <div class="hexa_two">
                    <div class="box-4"></div>
                    <div class="box-5"></div>
                    <div class="box-6"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss">
$color: #ff6e00;
$size: 12px;
$time: 1;

.full_page-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;

  .hexa-loader {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .hexa_one {
      position: absolute;

      .box-1 {
        float: left;
        border-right: 30px solid #444447;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
      .box-2 {
        float: left;
        width: 60px;
        height: 104px;
        background-color: #444447;
      }
      .box-3 {
        float: left;
        border-left: 30px solid #444447;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
    }

    .hexa_two {
      position: absolute;

      .box-4 {
        float: left;
        border-right: 30px solid #fff;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
      .box-5 {
        float: left;
        width: 60px;
        height: 104px;
        background-color: #fff;
      }
      .box-6 {
        float: left;
        border-left: 30px solid #fff;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
    }

    .hexa_three {
      position: absolute;
      transform: scale(0.34);

      .box-7 {
        float: left;
        border-right: 30px solid #ff6e00;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
      .box-8 {
        float: left;
        width: 60px;
        height: 104px;
        background-color: #ff6e00;
      }
      .box-9 {
        float: left;
        border-left: 30px solid #ff6e00;
        border-top: 52px solid transparent;
        border-bottom: 52px solid transparent;
      }
    }

    .hexa-left {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: moveRight 1s ease;
      margin-left: 0;

      .hexa_one {
        filter: drop-shadow(10px 0px 0px #fff);
        animation: none;
      }
      .hexa_two {
        animation: none;
      }
      .hexa_three {
        animation: none;
      }
    }
    .hexa-right {
      display: flex;
      justify-content: center;
      align-items: center;

      .hexa_one {
        filter: drop-shadow(10px 0px 0px #fff);
        animation: none;
        animation: moveLeft 1s ease;
      }
      .hexa_two {
        animation: none;
        animation: moveLeft 1s ease;
      }
    }
    .hexa_one {
      animation: none;
      filter: drop-shadow(10px 0px 0px #fff);
    }
    .hexa_two {
      animation: none;
      transform: scale(0.7);
    }
    .hexa-right {
      margin-left: 70px;
      z-index: -1;
    }

    .inner-loader {
      display: flex;
      flex-direction: column;
      align-items: center;

      .row {
        display: flex;
      }
    }

    .arrow {
      width: 0;
      height: 0;
      margin: 0 (-$size / 2);
      border-left: $size solid transparent;
      border-right: $size solid transparent;
      border-bottom: ($size * 1.8) solid $color;
      animation: blink $time + s infinite;
      filter: drop-shadow(0 0 ($size * 1.5) $color);

      &.down {
        transform: rotate(180deg);
      }

      @for $i from 1 through 18 {
        &.outer-#{$i} {
          animation-delay: -($time / 18) * $i + s;
        }
      }

      @for $i from 1 through 6 {
        &.inner-#{$i} {
          animation-delay: -($time / 6) * $i + s;
        }
      }
    }

    @keyframes blink {
      0% {
        opacity: 0.1;
      }
      30% {
        opacity: 1;
      }
      100% {
        opacity: 0.1;
      }
    }
  }
}

@keyframes moveLeft {
  0% {
    margin-left: -140px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes moveRight {
  0% {
    margin-left: 40px;
  }
  100% {
    margin-left: 0px;
  }
}
</style>
