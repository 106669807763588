/* eslint-disable */
import paymentApi from "@/api/payment";
const ERROR = {
    type: "error",
    title: "Something went wrong!",
    message: "Something went wrong please try again",
    button: {
        event: "back",
        text: "Try again"
    }
};
const SUCCEESS = {
    type: "success",
    title: "Payment successful!",
    message: "Your transaction was successfully processed",
    button: {
        event: "continue",
        text: "Set password"
    }
};
export default {
    namespaced: true,
    state: () => ({
        customerId: null,
        customerSecret: null,
        modalData: {},
        showModal: false,
        loading: false,
        planLength: 12,
        planUsers: 249
    }),
    actions: {
        getCustomerID({ state, rootState, commit, dispatch }, payload) {
            commit("startSpinner")
            const data = {
                is_stripe: true,
                uuid: payload.uuid,
                plan_length: state.planLength,
                plan_users: state.planUsers,
                plan: rootState.pricing.plan
            }
            if (payload.stripe) {
                paymentApi
                .getClientID(data)
                .then(response => {
                    commit("setCustomer", {
                        secret: response.data.clientSecret,
                        id: response.data.customer_id
                    });
                    dispatch("pay", {
                        card: payload.card,
                        name: payload.name,
                        stripe: payload.stripe
                    })
                })
                .catch(error => {
                    commit('stopSpinner')
                    commit("setErrorMessage", {
                        error: error.response.data.message
                    })
                    commit("openModal")
                })
            } else {
                data['purchase_number'] = payload.purchaseNumber
                data['note'] = payload.note
                data.is_stripe = false
                paymentApi
                .getClientID(data)
                .then(response => {
                    commit("stopSpinner");
                    if (response.data.status === 'success') {
                        commit("setSuccessMessage");
                        commit("openModal");
                    }
                })
                .catch(error => {
                    commit('stopSpinner')
                    commit("setErrorMessage", {
                        error: error.response.data.message
                    })
                    commit("openModal")
                })
            }
        },
        pay({ state, commit, dispatch }, payload) {
            const card = payload.card;
            const name = payload.name;
            payload.stripe
                .confirmCardPayment(state.customerSecret, {
                    payment_method: {
                        card: card,
                        billing_details: {
                            name: name
                        },
                    },
                    setup_future_usage: 'off_session'
                })
                .then(result => {
                    commit("stopSpinner");
                    if (result.error) {
                        commit("setErrorMessage", {
                            error: result.error.message
                        });
                        commit("openModal");
                    } else {
                        commit("setSuccessMessage");
                        commit("openModal");
                    }
                });
        },
        callSuccess({ state, commit, dispatch }, payload) {
            let attempts = 0
            const successStripe = async (resolve, reject) => {
                const res = await paymentApi.paymentSuccessInternal(payload.data)
                const result = await paymentApi.paymentSuccess(res.data.url, payload.data)
                attempts++
                if (result.data.success === true) {
                    return resolve(result)
                } else if (attempts === 50) {
                    return reject(new Error("Exceeded max attempts"));
                } else {
                    setTimeout(successStripe, 2000, resolve, reject);
                }
            }

            return new Promise(successStripe)
        }
    },
    mutations: {
        openModal: state => {
            state.showModal = true;
        },
        closeModal: state => {
            state.showModal = false;
        },
        setSuccessMessage: state => {
            state.modalData = SUCCEESS;
        },
        setErrorMessage: (state, payload) => {
            state.modalData = ERROR;
            if (payload.error) {
                state.modalData.message = payload.error;
            }
        },
        setCustomer: (state, payload) => {
            state.customerSecret = payload.secret;
            state.customerId = payload.id;
        },
        startSpinner: state => {
            state.loading = true;
        },
        stopSpinner: state => {
            state.loading = false;
        },
        setPlan: (state, payload) => {
            state.planLength = payload.length;
            state.planUsers = payload.users;
        },
        setLength: (state, payload) => {
            state.planLength = payload.length;
        },
        setUsers: (state, payload) => {
            state.planUsers = payload.users;
        }
    }
};
