import BaseApi from './BaseApi'

class Payment extends BaseApi {
    endpoint = '/payment'

    getClientID = (data) => this.axiosInstance.post(`${this.endpoint}/intent`, data)
    getPrices = () => this.axiosInstance.get(`${this.endpoint}/price`)
    paymentSuccessInternal= (data) => this.axiosInstance.post(`${this.endpoint}/success`, data)
    paymentSuccess = (url, data) =>
        this.axiosInstance.post(`https://${url}/api${this.endpoint}/success`, data)
}
export default new Payment()
