import signupApi from '@/api/signup.js'

let isDemo = window.location.host.startsWith('demo.')
let isFreemium = window.location.host.startsWith('privacymonitor.')

const FORM_FIELDS = {
    company_name: {
        type: 'input',
        class: 'fa fa-building form-control-feedback text-muted',
        input_type: 'text',
        value: '',
        input_message: '',
        error: false,
        empty: '',
        placeholder: 'Company Name'
    },
    country_code: {
        type: 'selectlist',
        select_type: 'object',
        options: {
            DK: {
                key: 'DK',
                value: 'DK-Denmark'
            },
            CA: {
                key: 'CA',
                value: 'CA-Canada'
            },
            US: {
                key: 'US',
                value: 'US-United States'
            },
            AT: {
                key: 'AT',
                value: 'AT-Austria'
            },
            BE: {
                key: 'BE',
                value: 'BE-Belgium'
            },
            BG: {
                key: 'BG',
                value: 'BG-Bulgaria'
            },
            CY: {
                key: 'CY',
                value: 'CY-Cyprus'
            },
            CZ: {
                key: 'CZ',
                value: 'CZ-Czech Republic'
            },
            DE: {
                key: 'DE',
                value: 'DE-Germany'
            },
            EE: {
                key: 'EE',
                value: 'EE-Estonia'
            },
            EL: {
                key: 'EL',
                value: 'EL-Greece'
            },
            ES: {
                key: 'ES',
                value: 'ES-Spain'
            },
            FI: {
                key: 'FI',
                value: 'FI-Finland'
            },
            FR: {
                key: 'FR',
                value: 'FR-France'
            },
            HR: {
                key: 'HR',
                value: 'HR-Croatia'
            },
            HU: {
                key: 'HU',
                value: 'HU-Hungary'
            },
            IE: {
                key: 'IE',
                value: 'IE-Ireland'
            },
            IT: {
                key: 'IT',
                value: 'IT-Italy'
            },
            LT: {
                key: 'LT',
                value: 'LT-Lithuania'
            },
            LU: {
                key: 'LU',
                value: 'LU-Luxembourg'
            },
            LV: {
                key: 'LV',
                value: 'LV-Latvia'
            },
            MT: {
                key: 'MT',
                value: 'MT-Malta'
            },
            NL: {
                key: 'NL',
                value: 'NL-The Netherlands'
            },
            PL: {
                key: 'PL',
                value: 'PL-Poland'
            },
            PT: {
                key: 'PT',
                value: 'PT-Portugal'
            },
            RO: {
                key: 'RO',
                value: 'RO-Romania'
            },
            SE: {
                key: 'SE',
                value: 'SE-Sweden'
            },
            SI: {
                key: 'SI',
                value: 'SI-Slovenia'
            },
            SK: {
                key: 'SK',
                value: 'SK-Slovakia'
            },
            XI: {
                key: 'XI',
                value: 'XI-Northern Ireland'
            }
        },
        value: 'DK',
        error: false,
        success: false,
        input_message: '',
        empty: null,
        placeholder: 'Select country'
    },
    vat_number: {
        type: 'inputtext',
        class: 'fa fa-building form-control-feedback text-muted',
        input_type: 'text',
        value: '',
        input_message: '',
        error: false,
        empty: null,
        placeholder: 'VAT number'
    },
    workspace: {
        type: 'input',
        class: 'fa fa-building form-control-feedback text-muted',
        input_type: 'text',
        regex: /^[a-z0-9_-]*$/,
        wrongFormatMessage: 'Wrong format! Workspace can contain letters (a-z), digits(0-9) and underscore (_)!',
        value: '',
        error: false,
        success: false,
        input_message: '',
        empty: '',
        min_length: 3,
        placeholder: 'Workspace'
    },
    username: {
        type: 'input',
        img: '',
        input_type: 'text',
        value: '',
        regex: /^[a-z0-9_-]*$/,
        wrongFormatMessage: 'Wrong format! Workspace can contain letters (a-z), digits(0-9) and underscore (_)!',
        error: false,
        success: false,
        input_message: '',
        empty: '',
        placeholder: 'Username'
    },
    full_name: {
        type: 'input',
        class: 'fa fa-user-circle form-control-feedback text-muted',
        input_type: 'text',
        value: '',
        error: false,
        success: false,
        input_message: '',
        empty: '',
        placeholder: 'Full Name'
    },
    email: {
        type: 'input',
        class: 'fa fa-envelope form-control-feedback text-muted',
        input_type: 'email',
        value: '',
        regex: /\S+@\S+\.\S+/,
        wrongFormatMessage: 'Wrong email format!',
        error: false,
        success: false,
        input_message: '',
        empty: '',
        placeholder: 'Email'
    },
    reference_code: {
        type: 'input',
        class: 'fa fa-user-circle form-control-feedback text-muted',
        input_type: 'text',
        value: '',
        error: false,
        success: false,
        hidden: !isFreemium,
        input_message: '',
        placeholder: 'Reference Code'
    },
    referrer: {
        type: 'selectlist',
        select_type: 'object',
        options: {
            google: {
                key: 'google',
                value: 'Google Search'
            },
            linkedin: {
                key: 'linkedin',
                value: 'LinkedIn'
            },
            d_market: {
                key: 'd_market',
                value: 'D-mærket'
            },
            partner: {
                key: 'partner',
                value: 'Recommended by Cyber Security partner'
            },
            auditor: {
                key: 'auditor',
                value: 'Recommended by auditor'
            },
            da_di: {
                key: 'da_di',
                value: 'DA/DI'
            },
            other: {
                key: 'other',
                value: 'Other'
            }
        },
        error: false,
        success: false,
        hidden: !isFreemium,
        input_message: '',
        empty: null
    },
    demo_account: {
        type: 'checkbox',
        hidden: true,
        value: isDemo || isFreemium
    },
    demo_type: {
        type: 'input',
        input_type: 'text',
        hidden: true,
        value: (isDemo && 'demo') || (isFreemium && 'free') || ''
    }
}

export default {
    namespaced: true,
    state: () => ({
        customerData: FORM_FIELDS,
        proceedToPrivacyPolicy: false
    }),
    actions: {
        register: ({ state, rootState, commit }) => {
            Object.keys(state.customerData).forEach((key) =>
                commit('fieldValidate', { fieldName: key })
            )
            const error = Object.values(state.customerData).find(
                (field) => field.error
            )
            if (error) {
                return false
            }
            let data = Object.keys(state.customerData).reduce((acc, curr) => {
                acc[curr] = state.customerData[curr].value
                return acc
            }, {})
            data = { ...data, plan: rootState.pricing.plan.toLowerCase(), plan_users: rootState.pricing.users }
            signupApi.registerCustomer(data).then((response) => {
                commit('procced')
            }).catch((error) => {
                const errorData = error.response.data
                for (let error in errorData) {
                    commit('setErrorMessage', { field: error, message: errorData[error].message })
                }
            })
        }
    },
    mutations: {
        fieldReset: (state, payload) => {
            state.customerData[payload.name].error = false
            state.customerData[payload.name].success = false
            state.customerData[payload.name].input_message = ''
        },
        fieldValidate: (state, { fieldName }) => {
            let data = state.customerData[fieldName]
            if (data.empty !== null && data.empty === data.value) {
                data.input_message = 'Can\'t leave empty!'
                data.error = true
            } else if (data.min_length !== undefined && data.value.length <= data.min_length) {
                data.input_message = `${data.placeholder} must have more then ${data.min_length} characters!`
                data.error = true
            } else if (data.regex !== undefined) {
                let regex = data.regex
                if (!regex.test(data.value)) {
                    data.input_message = data.wrongFormatMessage
                    data.error = true
                }
            } else if (data.confirm_password !== undefined) {
                if (data.value !== state.customerData[data.confirm_password].value) {
                    data.input_message = 'Passwords do not match.'
                    data.error = true
                }
            }
        },
        setErrorMessage: (state, payload) => {
            state.customerData[payload.field].error = true
            state.customerData[payload.field].input_message = payload.message
        },
        fieldChange: (state, payload) => {
            state.customerData[payload.name].value = payload.value
        },
        setCustomerData: (state, payload) => {
            if (payload.data) {
                Object.keys(state.customerData).forEach((key) => {
                    if (payload.data[key]) {
                        state.customerData[key].value = payload.data[key]
                    }
                })
            }
        },
        procced: (state) => {
            state.proceedToPrivacyPolicy = true
        },
        declinePrivacyPolicie: (state) => {
            state.proceedToPrivacyPolicy = false
        },
        countryCodeSet: (state, payload) => {
            state.customerData.country_code.value = payload.value
        },
        referrerSet: (state, payload) => {
            state.customerData.referrer.value = payload.value
        }
    }
}
