<template>
    <div class="action-dropdown" v-click-outside="hide" @click="open">
        <button
            v-if="icon_type === 'plus_btn'"
            title="Add"
            class="icon-button"
            :class="isOpen ? 'open' : ''"
        >
            <i :class="$font_icons['plus-s']"></i>
        </button>
        <button
            v-else
            type="button"
            data-toggle="dropdown"
            class="btn-more"
            :class="isOpen ? 'open' : ''"
        >
            <span v-if="mode === 'selectlist'" :key="selected.name">
                <span v-if="title && !selected.name">{{ title }}</span>
                <img
                    v-if="'img' in selected"
                    :src="selected.img"
                    alt=""
                    class="dropdown-image"
                />{{ selected.name }}
            </span>
            <i
                v-if="icon_type === 'ellipsis'"
                class="tag-button"
                :class="$font_icons['ellipsis-v']"
            />
            <i
                v-else
                aria-hidden="true"
                class="icon-dropdown"
                :class="$font_icons['chevron-down-s']"
            ></i>
        </button>
        <ul
            role="menu"
            class="dropdown-menu-a"
            :class="icon_type === 'plus_btn' ? 'middle' : ''"
        >
            <li
                v-for="(action, key) in actions"
                :key="key"
                @click.stop="select(action, key)"
                :class="setClass(action, key)"
            >
                <a>
                    <img
                        v-if="'img' in action"
                        :src="action.img"
                        alt=""
                        class="dropdown-image"
                    />{{ action.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
import ClickOutside from '@/directives/ClickOutside'
export default {
    name: 'actions',
    props: ['actions', 'mode', 'icon_type', 'selected', 'id', 'title'],
    directives: {
        ClickOutside
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        hide() {
            this.isOpen = false
        },
        open() {
            this.isOpen = !this.isOpen
            this.$emit('click')
        },
        select(action, key) {
            if (action.disabled) {
                return
            }
            this.hide()
            if (this.mode === 'action') {
                this.$emit(action.action, this.id, key)
            }
            if (this.mode === 'multiselect') {
                const index = this.selected.indexOf(action.id)
                if (index !== -1) {
                    this.selected.splice(index, 1)
                } else {
                    this.selected.push(action.id)
                }
                this.$emit('onchange')
            }
            if (this.mode === 'selectlist') {
                this.$emit('change', action, key)
            }
        },
        setClass(action, key) {
            let customClass = action.class || ''
            if (action.disabled) {
                customClass += ' disabled'
            }
            if (this.mode === 'action') {
                return customClass
            }
            if (this.mode === 'multiselect') {
                if (this.selected.indexOf(action.id) !== -1) {
                    return customClass + ' selected'
                }
            }

            if (this.mode === 'selectlist') {
                if (key === this.selected.key) {
                    return customClass + ' selected'
                }
            }

            return customClass
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '@/scss/components/dropdown';
.action-dropdown {
    position: relative;
    display: inline-block;
    align-self: flex-end;
    margin-block: -10px 10px;
    margin-right: -10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.16);
    }

    .dropdown-menu-a {
        position: absolute;
        right: 0;
        z-index: 9999999;
        display: none;
        float: left;
        max-height: 206px;
        min-width: 164px;
        width: 100%;
        padding: 2.5px 0;
        margin: 2px 0 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #fff;
        border-radius: $radius;
        box-shadow: $shadow-lg;
        background-clip: padding-box;
        overflow: auto;

        &:before {
            display: block;
            position: absolute;
            top: 1px;
            right: 8px;
            width: 0;
            height: 0;
            content: '';
            border: solid;
            border-width: 0px 5px 5px 5px;
            border-color: transparent;
            border-bottom-color: white;
            transform: translate(-50%, -100%);
            z-index: 2;
        }

        li {
            padding: 2.5px 5px;
            cursor: pointer;

            a {
                display: flex;
                align-items: center;
                padding: 0 10px;
                padding-right: 30px;
                font-size: 14px;
                color: color(typo, dark);
                letter-spacing: 0;
                text-align: left;
                line-height: 26px;
                border-radius: $radius-md;
                white-space: nowrap;

                &:hover {
                    text-decoration: none;
                    background-color: color(bg, light);
                }

                &:visited,
                &:active,
                &:focus {
                    text-decoration: none;
                }
            }

            &.disabled {
                a {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
                &:not(.selected) a:hover {
                    background-color: inherit;
                }
            }

            &.selected {
                a {
                    position: relative;
                    background-color: color(bg, green);
                    color: white;

                    &::after {
                        content: '\f00c';
                        position: absolute;
                        right: 5px;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        font-size: 8px;
                        line-height: 1;
                        float: right;
                        padding: 4px;
                        margin: 5px 0;
                        background: #ffffff4d;
                        border-radius: $radius-r;
                        animation: scale-up-center 0.4s
                            cubic-bezier(0.175, 0.885, 0.59, 1.65) both;
                    }
                }
            }

            &.last-menu-item {
                margin-top: 5px;
                padding-top: 5px;
                border-top: 1px solid color(bg, light-gray);

                &.warning {
                    a {
                        color: color(typo, red);

                        &:hover {
                            text-decoration: none;
                            background-color: lighten(color(bg, red), 34);
                        }
                    }
                }
            }
        }
    }

    .open + .dropdown-menu-a {
        display: block;
        animation: fade-in-bottom 0.08s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    .btn-more {
        padding: 10px 13.75px;
        // margin-left: 10px;
        font-size: 12px;
        line-height: 1;
        color: #888;
        border-radius: 50%;
        border: none;
        background: transparent;

        display: flex;
        align-items: center;
        width: 100%;

        span {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            margin-right: 5px;
            margin-left: 0;
        }
    }

    .btn-dropdown {
        padding: 10px 13.75px;
        margin-left: 0;
        font-size: 10px;
        line-height: 1;
        color: #888;
        border-radius: 50%;
        border: none;
        background: transparent;
    }

    /* Language select */
    .dropdown-image {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        border-radius: 50%;
    }
}

.action-dropdown_input {
    @extend .action-dropdown;

    .dropdown-menu-a {
        width: 100%;
    }
}

.cycle-btn {
    border-radius: 100%;
}

.icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    max-width: 24px;
    height: 24px;
    background: color(bg, light-gray);
    background: #f3f6f9;
    border-radius: $radius-md;
    margin-left: 20px;
    border: none;
    transition: 0.4s;

    i {
        width: 10px;
        height: 10px;
        font-size: 10px;
        vertical-align: middle;
        color: color(typo, link);
    }

    &:hover {
        background: hsl(210, 33%, 92%);
    }

    &.button-add {
        margin-left: 0;
    }
}

.middle {
    right: auto;
    left: 65%;
}

.icon-dropdown {
    margin-left: auto;
}

.language-dropdown {
    position: relative;

    .btn-more {
        display: inline-flex;
    }
    ul {
        max-height: 300px !important;
    }
}

@include respond-to('medium') {
    .language-dropdown {
        .dropdown-menu-a {
            max-height: 200px !important;
            bottom: 44px;
        }
    }
}

</style>
