<template>
<div :class="[data.error ? 'error ' : '', data.success ? 'success' : '', data.warning ? 'warning' : '']" class="input-field">
    <label v-if="data.name">{{data.name}}</label>
    <div class="input-wrap">
        <input @blur="$emit('focus-out')" :type="data.input_type" @click="$emit('reset')" :placeholder="data.placeholder" class="form-control" @input="handleChange" :value="data.value" :disabled="data.disabled" :min="data.min" :max="data.max" v-on:keyup.enter="$emit('enter')" autofocus>
        <span v-if="data.input_type !== 'number'" class="form-control-icon" :class="[data.error ? $font_icons['error'] : '', data.success ? $font_icons['success'] : '', data.warning ? $font_icons['warning'] : '', $font_icons[data.icon]]"></span>
    </div>
    <span v-if="data.helperText" class="helper-text">{{data.helperText}}</span>
    <span v-if="data.input_message" class="input-message">{{data.input_message}}</span>
</div>
</template>

<script>
export default {
    name: 'input-field',
    props: ['data'],
    data () {
        return {
            timeout: false,
            search: ''
        }
    },
    watch: {
        data: {
            handler () {
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    this.$emit('keyup')
                }, 1000)
            },
            deep: true
        }
    },
    methods: {
        handleChange (e) {
            this.$emit('change', e.target.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.input-field {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    label {
        font-size: 12px;
        font-weight: 500;
        color: color(typo, label);
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 0.25rem;
    }

    .label-edit {
        margin-left: 0.25rem;
        font-size: 12px;
        color: color(typo, label);
        line-height: 20px;
        background-color: transparent;
        outline-width: 0px;
        inset: 0px;
        border-width: 0px;
    }
    .input-wrap {
        position: relative;
        width: 100%;
        input {
            width: 100%;
            height: 36px;
            padding-left: 0.75rem;
            background: #f5f7fa;
            border: $border-light;
            border-radius: $radius;
            font-size: 14px;
            line-height: 26px;
            color: color(typo, dark);
            outline: none;
            transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            &[type='number'] {
                width: 100%;
                padding-right: 5px;
            }
            &::placeholder {
                font-size: 14px;
                color: color(typo, light);
                letter-spacing: 0;
                line-height: 26px;
            }
            &:hover {
                border-color: darken($color: #f5f7fa, $amount: 8);
            }
            &:focus {
                border: $border-blue;
                box-shadow: 0 0 0 4px rgba(93, 155, 236, 0.2);
            }
            &.disabled {
                background: darken($color: #f5f7fa, $amount: 4);
                opacity: 0.8;
            }
        }
    }
    .helper-text,
    .input-message {
        font-size: 10px;
        line-height: 20px;
        color: color(typo, gray);
    }
    .form-control-icon {
        &.form-control-icon {
            position: absolute;
            right: 0;
            left: auto;
            top: auto;
            bottom: 0;
            display: block;
            text-align: center;
            width: 36px;
            height: 36px;
            font-size: 12px;
            line-height: 36px;
            color: color(typo, link);
            z-index: 2;
        }
    }
    &.success {
        .input-wrap input {
            border: 1px solid color(bg, green);
        }
        .form-control-icon {
            &.form-control-icon {
                color: color(typo, green);
            }
        }
        .input-message {
            color: color(typo, green);
        }
    }
    &.warning {
        .input-wrap input {
            border: 1px solid color(bg, yellow);
        }
        .form-control-icon {
            &.form-control-icon {
                color: color(bg, yellow);
            }
        }
        .input-message {
            color: color(bg, yellow);
        }
    }
    &.error {
        .input-wrap input {
            border: 1px solid color(bg, red);
        }
        .form-control-icon {
            &.form-control-icon {
                color: color(typo, red);
            }
        }
        .input-message {
            color: color(typo, red);
        }
    }
}
</style>
