<template>
    <div class="wrapper">
        <div class="modal-overlay">
            <div class="modal-success">
                <div class="modal-body">
                    <i :class="[$font_icons[data.type], data.type]" class="modal-icon" />
                    <h3 class="modal-title">{{data.title}}</h3>
                    <p class="modal-text">{{data.message}}</p>
                    <button class="btn btn-success" @click.prevent="$emit(data.button.event)">{{data.button.text}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'success',
    props: ['data'],
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import "public/scss/components/buttons";
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: rgba(24, 24, 24, 0.64);
  z-index: 999999;

  &_plans {
    background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);
  }

  .modal-success {
    min-width: 480px;
    padding: 40px 20px 20px;
    background: white;
    border-radius: $radius-lg;

    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .modal-icon {
        font-size: 88px;
        margin-bottom: 30px;

        &.success {
          color: color(typo, green);
        }

        &.error {
          color: color(typo, red);
        }
      }

      .modal-title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 24px;
        color: color(typo, dark);
        letter-spacing: 0;
        text-align: center;
        line-height: 24px;
      }

      .modal-text {
        margin-bottom: 40px;
        font-weight: 300;
        font-size: 16px;
        color: color(typo, gray);
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
      }
    }
  }
}
</style>
