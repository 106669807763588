<template>
    <div>
        <div class="relv-form">
            <div class="licence-text" @scroll="handleScroll" ref="text">
                <slot></slot>
            </div>
            <div class="btns" v-if="reachedBottom === true">
                <button class="btn btn-ghost left" @click="decline">
                    Decline
                </button>
                <button class="btn btn-success right" @click="accept">
                    Accept
                </button>
            </div>
            <div class="btns" v-if="reachedBottom === false">
                <a class="scroll" @click="scrollDown">
                    <span></span> Scroll down
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data () {
        return {
            reachedBottom: false
        }
    },
    methods: {
        accept () {
            this.$emit('accept')
        },
        decline () {
            this.$emit('decline')
        },
        handleScroll (e) {
            let el = e.srcElement
            if (!this.reachedBottom) {
                if (el.scrollTop >= (el.scrollHeight - el.clientHeight) - 100) {
                    this.reachedBottom = true
                }
            }
        },
        scrollDown () {
            let el = this.$refs.text
            el.scrollBy({ top: 600, left: 0, behavior: 'smooth' })
        }
    },
    computed: mapState({
        customerData: (state) => state.signup.customerData
    })
}
</script>

<style lang="scss" scoped>
@import "public/scss/components/buttons";
.scroll {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 0 10px;
    padding-top: 45px;
    background: white;
    width: 120px;
    box-shadow: 0 0 16px rgb(0 0 0 / 5%), 0 0 1px rgb(0 0 0 / 10%);
    border-radius: 16px;
    font-size: 14px;

    span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 30px;
        height: 50px;
        margin-left: -15px;
        margin-bottom: 10px;
        border: 2px solid rgb(53, 53, 53);
        border-radius: 50px;
        box-sizing: border-box;
        transform: scale(.5);
        transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

        &::before {
            position: absolute;
            top: 10px;
            left: 50%;
            content: '';
            width: 6px;
            height: 6px;
            margin-left: -3px;
            background-color: rgb(57, 57, 57);
            border-radius: 100%;
            -webkit-animation: sdb10 2s infinite;
            animation: sdb10 2s infinite;
            box-sizing: border-box;
        }
    }

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 4px rgb(0 0 0 / 10%);
    }

    &:active {
        // box-shadow: 0 0 24px rgb(0 0 0 / 15%);
        -webkit-animation: shadow .2s infinite;
            animation: shadow .2s infinite;
        transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 4px rgb(0 0 0 / 5%);
  }
  50% {
    box-shadow: 0 0 48px rgb(0 0 0 / 5%);
  }
  100% {
    box-shadow: 0 0 24px rgb(0 0 0 / 5%);
  }
}

.licence-text {
    display: flex;
    font-size: 16px;
    color: #444447;
    letter-spacing: 0;
    text-align: justify;
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
    padding: 20px;
    background: #F5F7FA;
    border-radius: 8px;
}

.btns {
    margin-top: 20px;
}
</style>

<style lang="scss">
.policy-text {
    * {
        line-height: 1.2em;
        margin: 0 !important;
        margin-block-start: 0 !important;
        margin-block-end: 5px !important;
        padding: 0 !important;
    }
    h1 {
        margin-block-start: 20px !important;
        margin-block-end: 10px !important;
        font-size: 26px;
    }
    h2 {
        margin-block-start: 15px !important;
        margin-block-end: 10px !important;
        font-size: 20px;
    }
    h3 {
        margin-block-start: 10px !important;
        margin-block-end: 10px !important;
    }
    & > p:first-child img {
        display: block;
        max-width: 150px;
        margin: 0 auto !important;
        margin-bottom: 20px !important;
    }
    ul {
        li {
            padding-left: 10px !important;
        }
    }
}
</style>
