class Config {
    constructor () {
        if (process.env.VUE_APP_STAGE === 'production') {
            this.HOST = 'https://signup.dataandmore.com/'
            this.API = 'https://signup.dataandmore.com/api/'
            this.STRIPE_KEY = 'pk_live_Psn86vouKpTgZKfE2WuzHyh800c12c5GN5'
        } else if (process.env.VUE_APP_STAGE === 'dev') {
            this.HOST = 'https://signup.dataandmore.com/'
            this.API = 'https://signup.dataandmore.com/api/'
            this.STRIPE_KEY = 'pk_test_hcalVQgUQFvkOPkwZBnL0CZl00HJ0bOf3B'
        } else if (process.env.VUE_APP_STAGE === 'dockerdev') {
            this.HOST = '/'
            this.API = 'http://localhost:8080/api/'
            this.STRIPE_KEY = 'pk_test_hcalVQgUQFvkOPkwZBnL0CZl00HJ0bOf3B'
        } else {
            this.HOST = '/'
            this.API = 'https://signup.dataandmore.com/api/'
            this.STRIPE_KEY = 'pk_test_hcalVQgUQFvkOPkwZBnL0CZl00HJ0bOf3B'
        }

        this.localStorageNamespace = 'vuejs__'
    }
}

export default new Config()
