<template>
    <div class="outer-wrapper">
        <div class="wrapper">
            <!-- START Modal Change Plan -->
            <div class="modal-overlay modal-overlay_plans" v-if="loaded">
                <span class="back-button" @click="$router.go(-1)"
                    ><i :class="$font_icons['arrow-left-s']" /> <span>Back</span>
                </span>
                <h2 class="relv-title mb-4 mt-0">Change Plan</h2>
                <div class="cards">
                    <card
                        v-for="plan in plans"
                        :key="plan.id"
                        :data="plan"
                        :price="enterprisePlan"
                        :showButton="true"
                        :currency="currency"
                        @selectCard="selectCard(plan.id)"
                        @fullPricing="fullPricing"
                    ></card>
                </div>
                <button class="btn btn-success mt-6" @click="confirm">
                    Confirm selection
                </button>
            </div>
            <!-- END Modal Change Plan -->
        </div>
        <Table
            v-if="showTable"
            :prices="plans"
            :currency="currency"
            @close="showTable=false"
            @select="selectPricing"
        />
        <FullPageLoader v-if="!loaded"/>
    </div>
</template>

<script>
import Card from '@/components/pricing/Card'
import Table from '@/components/pricing/Table'
import { mapState } from 'vuex'
import router from '@/router/index'
import FullPageLoader from '@/components/loaders/FullPageLoader'

export default {
    name: 'changeplan',
    props: ['uuid'],
    components: {
        Card,
        Table,
        FullPageLoader
    },
    data () {
        return {
            showTable: false,
            prices: {},
            selectedPrice: 0
        }
    },
    computed: mapState({
        plans: (state) => state.pricing.plans,
        enterprisePlan: (state) => state.pricing.enterprisePlan,
        loaded: (state) => state.pricing.loaded,
        currency: (state) => state.pricing.currency
    }),
    methods: {
        selectCard (id) {
            this.$store.commit('pricing/choosePlan', { id: id })
        },
        fullPricing () {
            this.showTable = true
        },
        confirm () {
            router.push({ name: 'Payment', query: { uuid: this.uuid } })
        },
        selectPricing (model, n) {
            this.selectedPrice = n
            this.$store.commit('pricing/choosePaymentModel', { name: 'enterprise', enterprise: this.selectedPrice })
            this.selectCard(model)
            this.showTable = false
        }
    },
    mounted () {
        this.$store.dispatch('pricing/getPlans')
    }
}
</script>

<style lang="scss" scoped>
@import "public/scss/components/buttons";
.outer-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background: rgba(24, 24, 24, 0.64);
    z-index: 999999;

    &_plans {
      background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);
    }

    .cards {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 92px;
    }
  }
}

.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;

  span {
    display: none;
  }

  &:hover {
    cursor: pointer;

    span {
      display: inline-flex;
      margin-left: 5px;
      font-size: 12px;
      line-height: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

// Toggle style

@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: color(bg, blue),
  $can-toggle-inactive-text: color(typo, dark),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: lighten($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
        }
      }

      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
          }
        }
      }
    }
  }

  label {
    .can-toggle__label-text {
      flex: 1;
    }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
    }
  }

  label {
    width: $can-toggle-width;
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }
    }
  }
}

.can-toggle {
  position: relative;
  transform: scale(.8);
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;
      box-shadow: 0 0 0 1px #b7bac9; // act as a border

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-align: center;
        background: color(bg, blue);
        transform: translate3d(0, 0, 0);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  &.can-toggle {
    @include can-toggle-branding(
      white,
      // Off state
      white,
      // On state
      color(typo, black),
      // Off state text cubic-bezier(0.86, 0, 0.07, 1) / / Transition timing fun
    );
    @include can-toggle-appearance(
      244px,
      42px,
      42px,
      4px,
      16px,
      16px // Switch font
    );
  }
}

.btn-success {
  border-radius: 20px;
  animation: heartbeat 2s ease-in-out infinite both;
}
</style>
