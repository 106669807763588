<template>
    <div class="wrapper">
        <!-- START Signup-->
        <div class="panel">
            <div class="panel-left">
                <div v-if="!proceedToPolicy">
                    <header>
                        <img
                            v-if="customerData.demo_type.value === 'free'"
                            class="relv-logo"
                            src="../../../public/img/Privacy-Monitor_Logo.svg"
                            style="height: 32px;"
                        />
                        <img
                            v-else
                            class="relv-logo"
                            src="../../../public/img/Data_and_More_Logo_Dark.png"
                        />
                        <h2 class="relv-title">{{ $t('signup') }}</h2>
                    </header>
                    <div class="relv-form">
                        <div
                          class="form-group"
                          v-for="(field, key) in customerData"
                          :key="key"
                        >
                            <div
                                v-if="descriptions[key]"
                                class="description"
                                v-show="!field.hidden"
                            >
                              <i :class="$font_icons['info-circle']" v-tooltip="$t(`descriptions.${key}`)"></i>
                            </div>
                            <input-field
                                v-if="field.type==='input'"
                                v-show="!field.hidden"
                                @reset="resetFields(key)"
                                @change="inputChange(key, $event)"
                                :data="{
                                  name: $t(`form.${key}`),
                                  ...field
                                }"
                            />
                            <input-select v-else-if="field.type==='selectlist'"
                                :data="{
                                  name: $t(`form.${key}`),
                                  ...field
                                }"
                                v-show="!field.hidden"
                                @change="(value) => selectField(key, value)"
                                @reset="resetFields(key)"
                            />
                            <div
                                v-if="field.type==='inputtext'"
                                v-show="!field.hidden"
                                class="vat-number"
                            >
                                <span class="country-code">{{customerData.country_code.value}}</span>
                                <input-field
                                    @reset="resetFields(key)"
                                    @change="inputChange(key, $event)"
                                    :data="{
                                      name: $t(`form.${key}`),
                                      ...field
                                    }"
                                />
                            </div>
                            <input
                                type="checkbox"
                                v-if="field.type==='checkbox'"
                                v-show="!field.hidden"
                                v-model="field.value"
                            />
                        </div>
                        <button class="relv-btn" @click.prevent="register">
                            Continue
                        </button>
                        <p class="note" v-html="$t('footer')"></p>
                    </div>
                </div>
                <policy
                    v-else
                    @accept="accept"
                    @decline="decline"
                >
                    <div v-html="$sanitize(service_agreement_html)" class="policy-text"></div>
                </policy>
                <footer>
                    <span>© {{ new Date().getFullYear() }}, </span>
                    <a href="https://dataandmore.com/" target="_blank">
                        Data &amp; More
                    </a>,
                    <a href="https://gdpr.complycloud.com/externaldocument?id=82668239c563aeb047a881ec3beb46b584466376797655847120333533" class="privacy-link" target="_blank">
                        Privacy Policy
                    </a>
                </footer>
            </div>
            <div class="panel-right" v-if="showTable">
              <Table
                  :prices="plans"
                  :currency="currency"
                  @close="showTable=false"
                  @select="selectPricing"
              />
            </div>
            <div class="panel-right center" v-else-if="customerData.demo_account.value && customerData.demo_type.value === 'demo'">
              <languages/>
              <DemoScreen
                @fullPricing="showTable=true"
              />
            </div>
            <div class="panel-right center" v-else-if="customerData.demo_account.value && customerData.demo_type.value === 'free'">
              <languages/>
              <FreeScreen
                @fullPricing="showTable=true"
              />
            </div>
            <div class="panel-right" v-else>
              <languages/>
              <div class="plans">
                <h2 class="relv-title mb-4 mt-0">{{$t('pricing')}}</h2>
                <div class="cards">
                    <card
                        v-for="plan in plans"
                        :key="plan.id"
                        :data="plan"
                        :price="enterprisePlan"
                        :currency="currency"
                        :showButton="true"
                        @selectCard="selectCard"
                        @fullPricing="showTable=true"
                    ></card>
                </div>
              </div>
            </div>
        </div>
        <!-- END Signup-->
    </div>
</template>
<script>
import Policy from '@/components/forms/Policy'
import Card from '@/components/pricing/Card'
import Table from '@/components/pricing/Table'
import DemoScreen from '@/components/account/DemoScreen'
import FreeScreen from '@/components/account/FreeScreen'
import router from '@/router'
import InputField from '@/components/forms/InputField'
import signupApi from '@/api/signup.js'
import { mapState } from 'vuex'
import InputSelect from '../forms/InputSelect.vue'
import Languages from '../languages/Languages.vue'

export default {
    name: 'signup',
    components: {
        InputField,
        Policy,
        Card,
        Table,
        InputSelect,
        DemoScreen,
        FreeScreen,
        Languages
    },
    computed: mapState({
        customerData: (state) => state.signup.customerData,
        proceedToPolicy: (state) => state.signup.proceedToPrivacyPolicy,
        plans: (state) => state.pricing.plans,
        currency: (state) => state.pricing.currency,
        enterprisePlan: (state) => state.pricing.enterprisePlan
    }),
    data () {
        return {
            showTable: false,
            prices: {},
            selectedPrice: 0,
            descriptions: {
                workspace: this.$t('descriptions.workspace'),
                username: this.$t('descriptions.username'),
                vat_number: this.$t('descriptions.vat_number'),
                reference_code: this.$t('descriptions.reference_code')
            },
            service_agreement_html: ''
        }
    },
    methods: {
        selectPricing (model, n) {
            if (!this.customerData.demo_account.value) {
                this.selectedPrice = n
                this.$store.commit('pricing/choosePaymentModel', { name: 'enterprise', enterprise: this.selectedPrice })
                this.selectCard(model)
            }
            this.showTable = false
        },
        selectCard (id) {
            this.$store.commit('pricing/choosePlan', { id: id })
        },
        inputChange (key, value) {
            if (key === 'company_name' && this.safeWorkspaceName(this.customerData.company_name.value) === this.customerData.workspace.value) {
                this.$store.commit('signup/fieldChange', {
                    name: 'workspace',
                    value: this.safeWorkspaceName(value)
                })
            }
            this.$store.commit('signup/fieldChange', {
                name: key,
                value
            })
        },
        safeWorkspaceName (name) {
            name = name.toLowerCase()
            name = name.replace(/\s+/g, '_')
            name = name.replace(/[^a-z0-9_]/g, '')
            return name
        },
        resetFields (key) {
            this.$store.commit('signup/fieldReset', { name: key })
        },
        async register () {
            await this.$store.dispatch('signup/register')
            let response = await signupApi.getServiceAgreement({
                company_name: this.customerData.company_name.value,
                full_name: this.customerData.full_name.value,
                email: this.customerData.email.value
            })
            this.service_agreement_html = response.data.html
        },
        accept () {
            signupApi.customerEmail({
                email: this.customerData.email.value,
                workspace: this.customerData.workspace.value
            }).then((response) => {
                router.push({ name: 'MailVerify' })
            })
        },
        decline () {
            this.$store.commit('signup/declinePrivacyPolicie')
        },
        selectField (key, value) {
            if (key === 'country_code') {
                this.$store.commit('signup/countryCodeSet', { value })
                this.$store.commit('pricing/setCurrency', { value })
            } else if (key === 'referrer') {
                this.$store.commit('signup/referrerSet', { value })
            }
        },
        selectInitialPlan () {
            let params = new URLSearchParams(window.location.search)
            let plan = params.get('plan')
            let map = {
                exchange_online: 'basic',
                microsoft365: 'business',
                hybrid: 'premium'
            }
            plan = map[plan]
            if (!plan) {
                return
            }
            let unsubscribe = this.$store.subscribe((mutation) => {
                if (mutation.type !== 'pricing/setPlans') {
                    return
                }
                this.selectCard(plan)
                unsubscribe()
            })
        }
    },
    mounted () {
        this.$store.dispatch('pricing/getPlans')
        console.log(this.plans)
        this.selectInitialPlan()
    }
}
</script>

<style lang="scss" scoped>
// Toggle style

@mixin can-toggle-branding(
  $can-toggle-off-color: #777,
  $can-toggle-on-color: color(bg, blue),
  $can-toggle-inactive-text: color(typo, dark),
  $can-toggle-transition: cubic-bezier(0, 1, 0.5, 1)
) {
  input[type="checkbox"] {
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
      }
    }
    &:hover ~ label {
      color: darken($can-toggle-off-color, 5%);
    }
    &:checked {
      ~ label {
        &:hover {
          color: lighten($can-toggle-on-color, 3%);
        }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
        }
      }

      &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
          }
        }
      }
    }
  }

  label {
    .can-toggle__label-text {
      flex: 1;
    }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before {
        color: $can-toggle-inactive-text;
      }
      &:after {
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }
  }
}

@mixin can-toggle-appearance(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px
) {
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {
    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after {
            transform: translate3d(
              $can-toggle-width -
                ($can-toggle-switch-width + $can-toggle-offset),
              0,
              0
            );
          }
        }
      }
    }
  }

  label {
    width: $can-toggle-width;
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset;
        left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset * 2);
        font-size: $can-toggle-switch-font-size;
      }
    }
  }
}

.can-toggle {
  position: relative;
  transform: scale(0.8);
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch {
        opacity: 0.4;
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;
      box-shadow: 0 0 0 1px #b7bac9; // act as a border

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-align: center;
        background: color(bg, blue);
        transform: translate3d(0, 0, 0);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  &.can-toggle {
    @include can-toggle-branding(
      white,
      // Off state
      white,
      // On state
      color(typo, black),
      // Off state text cubic-bezier(0.86, 0, 0.07, 1) / / Transition timing fun
    );
    @include can-toggle-appearance(
      244px,
      42px,
      42px,
      4px,
      16px,
      16px // Switch font
    );
  }
}

.note {
  padding: 0 .5rem;
  margin-block: 20px;
  font-size: 12px;
  border-radius: $radius;
  color: color(typo, dark);
  text-align: center;
}
</style>

<style lang="scss">
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .panel {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 100vh;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    // overflow: hidden;

    @include respond-to("medium") {
      flex-direction: column;
    }

    .panel-left {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;
      scrollbar-width: none;

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      header {
        text-align: center;

        .relv-logo {
          height: 40px;
          margin-bottom: 0;
        }
      }

      .relv-form {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        animation: fade-in-right 0.4s ease both;

        .relv-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 36px;
          border-radius: $radius;
          background-color: color(bg, green);
          color: white;
          font-size: 14px;
          line-height: 20px;
          border: none;
          outline: none;
          transition: 0.3s ease-in-out;

          &:hover {
            background-color: darken(color(bg, green), 6);
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        // padding-top: 20px;
        font-size: 14px;
        line-height: 20px;
        color: color(typo, gray);

        a {
          margin-left: 5px;
          font-weight: 600;
          color: color(typo, dark);
        }
      }
    }

    .panel-right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      padding: 20px;
      // background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);

      @include respond-to("medium") {
        padding: 40px 50px;
      }

      @include respond-to("small") {
        padding: 20px;
      }

      &.center {
        justify-content: center;
      }

      .plans {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 32px;
        overflow: hidden;
        background-image: linear-gradient(270deg, #EFF1F7 23%, #e4e9f1 100%);
      }

      .relv-plans {
        display: grid;
        grid-template-columns: 1fr 1px 1fr;
        width: 100%;
        max-width: 500px;
        padding: 20px;
        background: #ffffff;
        box-shadow: -8px -8px 40px 0 #ffffff, 8px 8px 40px 0 #bebebe;
        border-radius: 16px;

        .devider {
          background-color: color(bg, light-gray);
        }

        .plan {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;

          h3 {
            font-size: 24px;
            font-weight: 400;
            color: #41b883;
            letter-spacing: 4.5px;
            text-align: center;
            line-height: 24px;
            text-transform: uppercase;
          }

          &:last-child {
            h3 {
              color: #1bc5bd;
            }
          }

          p {
            margin-bottom: 10px;
            font-size: 14px;
            color: #2e3032;
            letter-spacing: 2px;
            text-align: center;
            line-height: 20px;

            strong {
              font-size: 18px;
              font-weight: 600;
            }
          }
        }
      }

      .relv-illustration {
        max-width: 640px;
        width: 100%;
        margin-top: 60px;
        filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.1));
      }

      .relv-title,
      .relv-subtitle {
        padding: 0 30px;
      }

      .cards {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 64px;
      }
    }

    .relv-title {
      margin: 20px 0 20px;
      font-size: 20px;
      font-weight: 600;
      color: color(typo, dark);
      text-align: center;
      line-height: 38px;
    }

    .relv-subtitle {
      max-width: 640px;
      margin-bottom: 40px;
      font-size: 14px;
      line-height: 18px;
      color: color(typo, gray);
      letter-spacing: 0;
      text-align: center;

      b {
        font-weight: 600;
      }
    }

    .relv-small-text {
      display: inline;
      margin: 10px 0 0;
      font-size: 12px;
      color: color(typo, gray);
      text-align: center;
    }

    .btn-ghost {
      padding: 8px 30px;
      border: 1px solid #1bc5bd;
      border-radius: $radius;
      color: #1bc5bd;
      font-size: 14px;
      line-height: 16px;

      &:hover {
        background-color: #1bc5bd;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
.wrapper {
  .panel {
    display: grid !important;
    grid-template-columns: 620px 1fr;

    .panel-left {
      width: 100%;
      padding: 20px 80px 20px !important;
    }
  }
}
.vat-number {
  display: flex;
  align-items: flex-start;

  .country-code {
    width: 36px;
    height: 36px;
    padding-left: 0.5rem;
    background: hsl(216, 33%, 94%);
    border: 1px solid #eeeeee;
    border-radius: $radius;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 14px;
    line-height: 36px;
    color: #686868;
    outline: none;
    margin-bottom: 15px;
    margin-top: 24px;
    cursor: not-allowed;
  }

  .input-field {
    label {
      margin-left: -1.75rem;
    }
    .input-wrap {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.relv-btn {
  &:hover {
    background-color: color(bg, green) !important;
    filter: brightness(1.04);
  }
}

.description {
  position: absolute;
  right: 0;
  z-index: 10;
  translate: -4px 4px;
  opacity: 0.64;
  transition: 0.3s ease-in-out;

  i {
    font-size: 14px;
    color: color(typo, link);
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.privacy-link {
  font-weight: normal !important;
  color: color(typo, link) !important;
}

.policy-text {
  line-height: 1.3rem;

  h2 {
    margin-block: 1rem 0.5rem;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
    font-weight: 700;
  }

  h3 {
    color: color(typo, dark);
    opacity: 0.8;
    font-weight: 600;
  }

  img {
    max-width: 100%;
  }
}
</style>
