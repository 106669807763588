<template>
<div class="input-select">
    <label v-if="data.name">{{data.name}}</label>
    <div class="input-wrap">
        <select v-if="data.select_type === 'object'" @change="handleChange" v-model="value" class="form-control" :disabled="data.disabled" @click="$emit('reset')">
            <option v-for="option in data.options" :value="option.key">{{option.value}}</option>
        </select>
        <select v-else @change="$emit('change')" v-model="data.value" class="form-control">
            <option v-for="option in data.options" :value="option">{{option}}</option>
        </select>
        <span class="form-control-icon" :class="$font_icons['chevron-down-s']"></span>
    </div>
    <span v-if="data.helperText" class="helper-text">{{data.helperText}}</span>
    <span v-if="data.input_message" class="input-message">{{data.input_message}}</span>
</div>
</template>

<script>
export default {
    name: 'input-select',
    props: ['data'],
    data () {
        return {
            value: this.data.value
        }
    },
    methods: {
        callback () {
            if (this.data.callback) {
                this.data.callback(this.data.value)
            }
        },
        handleChange (e) {
            this.$emit('change', e.target.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.input-select {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;

  label {
    font-size: 12px;
    font-weight: 500;
    color: color(typo, label);
    line-height: 20px;
    margin-bottom: 0.25rem;
  }
  .input-wrap {
    position: relative;
    min-width: 180px;
    display: flex;
    align-items: center;

    select {
      display: block;
      height: 36px;
      padding-left: 0.75rem;
      background: #f5f7fa;
      border: $border-light;
      border-radius: $radius;
      font-size: 14px;
      line-height: 1.2;
      color: color(typo, dark);
      box-sizing: border-box;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      padding-right: 28px; // fix longer text overflow in short inputs
      outline: none;

      option {
        background-color: white;
      }

      &:hover {
        border-color: darken($color: #f5f7fa, $amount: 8);
      }

      &:focus {
        border: $border-blue;
        box-shadow: 0 0 0 4px rgba(93, 155, 236, 0.2);
      }

      &:disabled {
        background: darken($color: #f5f7fa, $amount: 4);
        opacity: 0.8;
      }
    }
  }

  .helper-text,
  .input-message {
    font-size: 10px;
    line-height: 20px;
    color: color(typo, gray);
  }

  .form-control-icon {
    width: 100%;
    &.form-control-icon {
      position: absolute;
      right: 12px;
      left: auto;
      top: auto;
      bottom: auto;
      display: block;
      text-align: center;
      width: 12px;
      height: 12px;
      font-size: 12px;
      line-height: 12px;
      color: color(typo, gray);
      pointer-events: none;
    }
  }

  &.success {
    input {
      border: 1px solid color(bg, green);
    }

    .form-control-icon {
      &.form-control-icon {
        color: color(typo, green);
      }
    }
  }

  &.warning {
    input {
      border: 1px solid color(bg, yellow);
    }

    .form-control-icon {
      &.form-control-icon {
        color: color(bg, yellow);
      }
    }
  }

  &.error {
    input {
      border: 1px solid color(bg, red);
    }

    .form-control-icon {
      &.form-control-icon {
        color: color(typo, red);
      }
    }
  }
}
</style>
