export default {
    flags_lang: {
        English: '/img/flags/1x1/gb.svg',
        General: '/img/flags/1x1/gb.svg',
        Danish: '/img/flags/1x1/dk.svg',
        Polish: '/img/flags/1x1/pl.svg',
        Dutch: '/img/flags/1x1/nl.svg',
        French: '/img/flags/1x1/fr.svg',
        German: '/img/flags/1x1/de.svg',
        Italian: '/img/flags/1x1/it.svg',
        Norwegian: '/img/flags/1x1/no.svg',
        Portuguese: '/img/flags/1x1/pt.svg',
        Spanish: '/img/flags/1x1/es.svg',
        Swedish: '/img/flags/1x1/sv.svg',
        Finnish: 'img/flags/1x1/fi.svg'
    }
}
