<template>
  <div class="wrapper">
    <!-- START Payment-->
    <div class="panel payment" v-if="loaded">
      <div class="panel-left">
        <span class="back-button" @click="$router.go(-1)"
          ><i :class="$font_icons['arrow-left-s']" /> <span>Back</span>
        </span>

        <header>
          <img
            class="relv-logo"
            src="../../../public/img/Data_and_More_Logo_Dark.png"
          />
          <h2 class="relv-title">Payment Details</h2>
          <p class="relv-subtitle">
            Revise selected plan and payment information.
          </p>
        </header>

        <div class="payment-details">
          <div class="summary">
            <payment-details
              v-if="loaded"
              :uuid="uuid"
              :prices="plans"
              :lengths="planLength"
              :currency="currency"
              :coupon="coupon"
              @invoice="changeToInvoice"
            />
            <!-- Add opened class for redeemed Coupon -->
            <div
              class="coupon"
              :class="[
                showCoupon ? 'opened' : '',
                applyCouponError ? 'error' : '',
              ]"
            >
              <div class="l-circle">
                <div class="l-circle_inner"></div>
              </div>
              <div class="r-circle">
                <div class="r-circle_inner"></div>
              </div>
              <div class="content">
                <p>Enter coupon code</p>
                <input v-model="couponCode" type="text" placeholder="code" />
                <span v-if="applyCouponError" class="error-text">
                  Invalid coupon code!
                </span>
                <button @click="applyCoupon">Redeem coupon</button>
              </div>
              <div class="redeem-content">
                <!-- <p>Coupon for:</p> -->
                <h2>{{ coupon.name }}</h2>
              </div>
            </div>
          </div>
          <div class="card-details">
            <div class="user-info">
              <h4 class="summary-title">Account information</h4>
              <div>
                <span class="info-name">Company name:</span>
                <span>{{ customerData.company_name.value }}</span>
              </div>
              <div>
                <span class="info-name">Full name:</span>
                <span>{{ customerData.full_name.value }}</span>
              </div>
              <div>
                <span class="info-name">Workspace:</span>
                <span>{{ customerData.workspace.value }}</span>
              </div>
              <div>
                <span class="info-name">Email:</span>
                <span>{{ customerData.email.value }}</span>
              </div>
              <div>
                <span class="info-name">VAT number:</span>
                <span>{{
                  customerData.country_code.value +
                  " " +
                  customerData.vat_number.value
                }}</span>
              </div>
            </div>
            <div class="card-details_inner" v-if="!showInvoice">
              <h4 class="text-left">Card Details</h4>
              <div class="form">
                <div class="form-group">
                  <div class="input-field">
                    <label>Name on card</label>
                    <div class="input-wrap">
                      <input
                        placeholder="Name on card"
                        autofocus="autofocus"
                        type="text"
                        class="form-control"
                        v-model="name"
                      />
                      <span class="form-control-icon"></span>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div id="card-info">
                    <label>Card information</label>
                    <div class="fieldset">
                      <div id="card-number" class="field empty"></div>
                      <div
                        id="card-expiry"
                        class="field empty half-width"
                      ></div>
                      <div id="card-cvc" class="field empty half-width"></div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-success"
                @click.prevent="pay"
                :disabled="disable"
              >
                <div v-if="loading" class="spinner" id="spinner"></div>
                <span v-else id="button-text">Pay now</span>
              </button>

              <!-- <div class="error red center-align white-text">{{stripeValidationError}}</div> -->
              <div class="error" role="alert" v-if="stripeValidationError">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <path
                    class="base"
                    fill="#F05050"
                    d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
                  ></path>
                  <path
                    class="glyph"
                    fill="#FFF"
                    d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
                  ></path>
                </svg>
                <span class="message">{{ stripeValidationError }}</span>
              </div>
            </div>
            <div class="card-details_inner" v-else>
              <h4 class="text-left">Invoice Details</h4>
              <div class="form">
                <div class="form-group">
                  <div class="input-field">
                    <label>Purchase Number</label>
                    <div class="input-wrap">
                      <input
                        placeholder="Purchase Number"
                        autofocus="autofocus"
                        type="text"
                        class="form-control"
                        v-model="purchaseNumber"
                      />
                      <span class="form-control-icon"></span>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-field">
                    <label for="customerNotes">Invoice Notes</label>
                    <div class="input-wrap">
                      <textarea
                        name="InvoiceNotes"
                        id="customerNotes"
                        placeholder="Invoice Notes"
                        :value="notes"
                        @input="handleNotesChange"
                      ></textarea>
                    </div>
                  </div>
                  <button
                    class="btn btn-success"
                    @click.prevent="pay"
                    :disabled="purchaseNumber.length === 0"
                  >
                    <div v-if="loading" class="spinner" id="spinner"></div>
                    <span v-else id="button-text">Pay now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <span>© {{ new Date().getFullYear() }}, </span>
          <a href="https://dataandmore.com/" target="_blank">
            Data &amp; More
          </a>
        </footer>
      </div>
      <div class="panel-right">
        <h2 class="relv-title mb-2 mt-0">Current Plan</h2>
        <h4>
          <router-link
            :to="{ path: '/change-plan', query: { uuid: uuid } }"
            class="btn-change_plan mb-2 mt-4"
            >Change Plan</router-link
          >
        </h4>
        <div class="cards">
          <card
            :data="card"
            :prices="plans"
            :price="enterprisePlan"
            :currency="currency"
            :showButton="false"
          ></card>
        </div>
      </div>
    </div>
    <Success
      v-if="showModal"
      :data="modalData"
      @continue="continueLogin"
      @back="back"
    />
    <!-- END Payment-->

    <FullPageLoader v-if="!loaded"></FullPageLoader>
  </div>
</template>

<script>
/* eslint-disable */
import Success from "@/components/modals/Success";
import Card from "@/components/pricing/Card";
import FullPageLoader from "@/components/loaders/FullPageLoader";
import { mapState } from "vuex";
import PaymentDetails from "../pricing/PaymentDetails.vue";
import router from "@/router";
import signupApi from "@/api/signup";
import config from "@/config/Config";

let ready = false

export default {
  props: ["uuid"],
  name: "payment",
  components: {
    Success,
    Card,
    PaymentDetails,
    FullPageLoader,
  },
  computed: mapState({
    plans: (state) => state.pricing.plans,
    card: (state) => state.pricing.card,
    enterprisePlan: (state) => state.pricing.enterprisePlan,
    modalData: (state) => state.payment.modalData,
    showModal: (state) => state.payment.showModal,
    loading: (state) => state.payment.loading,
    loaded: (state) => state.pricing.loaded,
    planLength: (state) => state.pricing.planLength,
    customerData: (state) => state.signup.customerData,
    currency: (state) => state.pricing.currency,
  }),
  data() {
    return {
      disable: true,
      stripe: Stripe(config.STRIPE_KEY),
      name: "",
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      stripeValidationError: "",
      showInvoice: false,
      showCoupon: false,
      applyCouponError: false,
      couponCode: "",
      coupon: {},
      notes: "",
      purchaseNumber: "",
    };
  },
  async mounted() {
    if (ready) {
      return
    }

    let response = await signupApi.getCustomer({
      uuid: this.uuid
    })
    let customer = response.data

    this.$store.commit('pricing/setPayment', {
      plan: customer.plan,
      isEnterprise: customer.is_enterprise,
      users: customer.plan_users
    })
    this.$store.commit('signup/setCustomerData', {
      data: customer
    })

    let data = Object.keys(this.customerData).reduce((acc, curr) => {
      acc[curr] = this.customerData[curr].value
      return acc
    }, {})

    try {
      await signupApi.registerCustomer({ ...data, uuid: this.uuid })
    }
    catch (error) {
      this.setValidationError({error: {message: "Something went wrong"}})
      return
    }

    this.$store.commit("pricing/setCurrency", {
      value: customer.country_code,
    });
    this.$store.commit("signup/setCustomerData", {
      data: customer,
    });
    this.$store.commit("pricing/getPaymentDetails");
    this.$store.dispatch("pricing/getPlans");

    if (this.loaded && !this.showInvoice) {
      setTimeout(this.createAndMountFormElements, 500);
    }

    ready = true
  },
  watch: {
    loaded: function () {
      if (!this.showInvoice) {
        setTimeout(this.createAndMountFormElements, 500);
      }
    },
    showInvoice: function () {
      if (!this.showInvoice) {
        setTimeout(this.createAndMountFormElements, 500);
      }
    },
  },
  methods: {
    applyCoupon() {
      signupApi
        .applyCoupon({
          uuid: this.uuid,
          coupon_code: this.couponCode,
        })
        .then((response) => {
          this.coupon = response.data.coupon;
          this.showCoupon = true;
        })
        .catch((error) => {
          this.applyCouponError = true;
        });
    },
    createAndMountFormElements() {
      let elements = this.stripe.elements();
      const style = {
        base: {
          iconColor: "#ffffff",
          color: "#ffffff",
          fontWeight: "500",
          fontSize: "14px",
          fontSmoothing: "antialiased",
          letterSpacing: "3px",
          ":-webkit-autofill": {
            color: "#ffffff",
          },
          "::placeholder": {
            color: "#b7bac9",
          },
        },
        complete: {
          color: "#41b883",
        },
      };
      this.cardNumberElement = elements.create("cardNumber", {
        showIcon: true,
        style: style,
      });
      this.cardNumberElement.mount("#card-number");
      this.cardExpiryElement = elements.create("cardExpiry", {
        style: style,
      });
      this.cardExpiryElement.mount("#card-expiry");
      this.cardCvcElement = elements.create("cardCvc", {
        style: style,
      });
      this.cardCvcElement.mount("#card-cvc");
      this.cardNumberElement.on("change", this.setValidationError);
      this.cardExpiryElement.on("change", this.setValidationError);
      this.cardCvcElement.on("change", this.setValidationError);
    },
    setValidationError(event) {
      this.disable = event.error;
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    pay() {
      this.$store.commit("payment/startSpinner");
      if (!this.showInvoice) {
        this.$store.dispatch("payment/getCustomerID", {
          card: this.cardNumberElement,
          name: this.name,
          uuid: this.uuid,
          stripe: this.stripe,
        });
      } else {
        this.$store.dispatch("payment/getCustomerID", {
          purchaseNumber: this.purchaseNumber,
          note: this.notes,
          uuid: this.uuid,
        });
      }
      this.disable = true;
    },
    continueLogin() {
      router.push({ name: "SetPassword", query: { uuid: this.uuid } });
    },
    back() {
      location.reload();
    },
    changeToInvoice(price) {
      if (price >= 1000000) {
        this.showInvoice = true;
      } else {
        this.showInvoice = false;
      }
    },
    handleNotesChange(e) {
      this.notes = e.target.value;
    },
  },
};
</script>
<style lang="scss">
#card-info {
  label {
    margin-left: 0.25rem;
    font-size: 12px;
    color: color(typo, label);
    color: white;
    line-height: 20px;
  }

  .fieldset {
    padding: 0;
    border-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .field {
    width: 100%;
    height: 36px;
    padding: 0.5rem;
    padding-left: 0.75rem;
    background: transparent;
    border: 1px solid hsl(215deg 30% 32%);
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    font-size: 14px;
    color: color(typo, dark);
    color: white;
    outline: none;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    letter-spacing: 3px;

    &::placeholder {
      color: #b7bac9 !important;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
    }

    &:hover {
      border-color: darken($color: #f5f7fa, $amount: 8);
    }

    &:focus {
      border: $border-blue;
    }

    &.StripeElement--focus {
      border-bottom: $border-blue;
    }

    &.disabled {
      background: darken($color: #f5f7fa, $amount: 4);
      opacity: 0.8;
    }

    #placeholder {
      color: #f5f7fa;
    }
  }

  .field.half-width {
    width: calc(50%);
  }

  .field.focus,
  .field:focus {
    color: #424770;
    background-color: red;
    border: 1px solid red;
  }

  .field.invalid {
    background-color: #fa755a;
  }

  .field.invalid.focus {
    background-color: #f6f9fc;
  }

  .field.focus::-webkit-input-placeholder,
  .field:focus::-webkit-input-placeholder,
  .field::-webkit-input-placeholder {
    color: #b7bac9;
  }

  .field.focus::-moz-placeholder,
  .field:focus::-moz-placeholder {
    color: #b7bac9;
  }

  .field.focus:-ms-input-placeholder,
  .field:focus:-ms-input-placeholder {
    color: #b7bac9;
  }

  #card-number {
    /* font-size: 14px; */
    /* letter-spacing: 3px; */
    line-height: 26px;
    color: white !important;
  }

  #card-expiry,
  #card-cvc {
    margin-top: 5px;
    display: inline;
    width: 48%;
    color: white !important;

    &input {
      color: white !important;
    }

    &::placeholder {
      color: #b7bac9 !important;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 26px;
    }
  }

  #card-expiry {
    border-bottom-right-radius: 0 !important;
  }

  #card-cvc {
    border-bottom-left-radius: 0 !important;
  }

  input,
  button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
  }

  input {
    color: #fff;
  }

  input::-webkit-input-placeholder {
    color: #b7bac9 !important;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
  }

  input::-moz-placeholder {
    color: #b7bac9;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
  }

  input:-ms-input-placeholder {
    color: #b7bac9;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 26px;
  }

  button {
    display: block;
    width: calc(100% - 30px);
    height: 40px;
    margin: 0 15px;
    background-color: #fcd669;
    border-radius: 20px;
    color: #525f7f;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
  }

  button:active {
    background-color: #f5be58;
  }

  .error svg .base {
    fill: #fa755a;
  }

  .error svg .glyph {
    fill: #fff;
  }

  .error .message {
    color: #fff;
  }

  .success .icon .border {
    stroke: #fcd669;
  }

  .success .icon .checkmark {
    stroke: #fff;
  }

  .success .title {
    color: #fff;
  }

  .success .message {
    color: #9cabc8;
  }

  .success .reset path {
    fill: #fff;
  }
}

#spinner {
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-bottom-color: color(bg, brand);
  border-radius: 50%;
  display: inline-block;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<style lang="scss" scoped>
@import "public/scss/components/buttons";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .payment {
    display: flex !important;
    flex-direction: row;
    max-width: 100vw;
    min-height: 100vh;
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    overflow: hidden;

    .panel-left {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      flex: 1;
      padding: 30px 30px;

      header {
        text-align: center;

        .relv-logo {
          height: 50px;
          margin-bottom: 0px;
        }

        .relv-subtitle {
          max-width: 100%;
          margin-bottom: 40px;
          font-size: 14px;
          color: color(typo, gray);
          letter-spacing: 0;
          text-align: center;

          b {
            font-weight: 600;
          }
        }
      }

      .payment-details {
        display: flex;
        justify-content: space-between;

        h3 {
          width: 100%;
          margin-bottom: 20px;
          font-weight: 700;
          font-size: 18px;
          color: #333333;
          letter-spacing: 0;
          line-height: 38px;
        }

        .summary,
        .card-details {
          width: calc(50% - 10px);
        }

        .summary {
          .plan-name_title,
          .price_title,
          .active-accounts_title,
          .contract-period_title {
            font-weight: 600;
            font-size: 14px;
            color: #b7bac9;
            text-transform: uppercase;
            line-height: 24px;
          }

          .price_title {
            text-align: right;
          }

          div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;

            span {
              width: 50%;
            }
          }

          .coupon {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px auto 0;
            width: 90%;
            height: auto;
            filter: drop-shadow(0 0 10px rgb(0 0 0 / 8%));
            padding: 30px 60px;
            border-radius: 14px;
            overflow: hidden;

            .l-circle {
              display: flex;
              position: absolute;
              top: 0;
              left: 0;
              right: 50%;
              bottom: 0;
              height: 100%;
              background-image: radial-gradient(
                circle at 0 50%,
                transparent 22px,
                #edf3f5 0
              );
              z-index: 0;

              .l-circle_inner {
                display: flex;
                position: absolute;
                top: 2px;
                left: 2px;
                right: 0;
                bottom: 2px;
                height: calc(100% - 4px);
                background-image: radial-gradient(
                  circle at -2px 50%,
                  transparent 24px,
                  #ffffff 0
                );
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
              }
            }

            .r-circle {
              display: flex;
              position: absolute;
              top: 0;
              left: 50%;
              right: 0;
              bottom: 0;
              height: 100%;
              background-image: radial-gradient(
                circle at 100% 50%,
                transparent 22px,
                #edf3f5 0
              );
              z-index: 0;

              .r-circle_inner {
                display: flex;
                position: absolute;
                top: 2px;
                left: 0;
                right: 2px;
                bottom: 2px;
                height: calc(100% - 4px);
                background-image: radial-gradient(
                  circle at calc(100% + 2px) 50%,
                  transparent 24px,
                  #ffffff 0
                );
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
              }
            }

            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 20px;
              flex: 1;
              margin: 0;
              z-index: 1;

              p {
                font-size: 18px;
                font-weight: 700;
                color: #444444;
                letter-spacing: 0;
                text-align: center;
                line-height: 22px;
              }

              input {
                width: 100%;
                padding: 4px 10px;
                background: #f5f7fa;
                border-radius: 4px;
                border: none;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                letter-spacing: 6px;
                text-align: center;
                line-height: 28px;
                text-transform: uppercase;

                &::placeholder {
                  color: #b7bac9;
                }
              }

              button {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px 15px 8px 42px;
                background-color: #41b883;
                font-size: 14px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 16px;
                text-transform: uppercase;
                border: none;
                border-radius: 16px;
                opacity: 1;
                transition: all 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275);

                &::before {
                  content: "";
                  position: absolute;
                  left: 0;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 32px;
                  height: 32px;
                  background-color: rgba(255, 255, 255, 0.2);
                  background-image: url("/img/ticket.gif");
                  background-size: 16px;
                  background-repeat: no-repeat;
                  background-position: 50%;
                  border-radius: 50%;
                }

                &:hover {
                  cursor: pointer;
                  opacity: 0.8;
                }

                &:disabled {
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }
            }

            .redeem-content {
              display: none;
            }

            &.success {
              .l-circle {
                background-image: radial-gradient(
                  circle at 0 50%,
                  transparent 22px,
                  #41b883 0
                );
              }

              .r-circle {
                background-image: radial-gradient(
                  circle at 100% 50%,
                  transparent 22px,
                  #41b883 0
                );
              }
            }

            &.error {
              input {
                outline: $border-error;
                outline-width: 2px;
                color: #f05050;
                -webkit-animation: vibrate-1 0.3s linear both;
                animation: vibrate-1 0.3s linear both;
              }

              .error-text {
                margin: -20px 0;
                font-size: 12px;
                color: #f05050;
                letter-spacing: 0;
                text-align: center;
                line-height: 20px;
              }

              .l-circle {
                background-image: radial-gradient(
                  circle at 0 50%,
                  transparent 22px,
                  #f05050 0
                );
              }

              .r-circle {
                background-image: radial-gradient(
                  circle at 100% 50%,
                  transparent 22px,
                  #f05050 0
                );
              }
            }

            &.opened {
              height: 190px;

              .l-circle {
                background-image: radial-gradient(
                  circle at 0 50%,
                  transparent 22px,
                  #41b883 0
                );

                .l-circle_inner {
                  background-image: radial-gradient(
                    circle at -2px 50%,
                    transparent 24px,
                    hsl(159, 60%, 50%) 0
                  );

                  &::after {
                    content: "";
                    display: flex;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    right: 0;
                    bottom: 8px;
                    height: calc(100% - 16px);
                    background-image: radial-gradient(
                      circle at -8px 50%,
                      transparent 32px,
                      #41b883 0
                    );
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                  }
                }
              }

              .r-circle {
                background-image: radial-gradient(
                  circle at 100% 50%,
                  transparent 22px,
                  #41b883 0
                );

                .r-circle_inner {
                  background-image: radial-gradient(
                    circle at calc(100% + 2px) 50%,
                    transparent 24px,
                    hsl(159, 60%, 50%) 50,
                    hsl(159, 60%, 50%) 0
                  );

                  &::after {
                    content: "";
                    display: flex;
                    position: absolute;
                    top: 8px;
                    left: 0;
                    right: 8px;
                    bottom: 8px;
                    height: calc(100% - 16px);
                    background-image: radial-gradient(
                      circle at calc(100% + 8px) 50%,
                      transparent 32px,
                      #41b883 0
                    );
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                  }
                }
              }

              .content {
                display: none;
              }

              .redeem-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0;
                z-index: 1;

                h2 {
                  font-size: 24px;
                  font-weight: 600;
                  color: #ffffff;
                  letter-spacing: 0;
                  text-align: center;
                  line-height: 40px;
                }
              }

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-image: url("/img/pattern.svg");
                opacity: 0.6;
              }
            }
          }

          .plan-name,
          .active-accounts,
          .contract-period {
            font-weight: 600;
            font-size: 18px;
            color: #444447;
            line-height: 24px;

            span {
              font-weight: normal;
              font-size: 14px;
              color: color(typo, link);
              line-height: 24px;

              &:hover {
                cursor: pointer;
                text-decoration: underline;
              }
            }
          }

          .price,
          .active-accounts_price,
          .contract-period_time {
            font-weight: 700;
            font-size: 18px;
            color: #444447;
            letter-spacing: 0;
            text-align: right;
            line-height: 24px;

            span {
              font-weight: normal;
              font-size: 14px;
              color: color(typo, gray);
              line-height: 24px;
            }
          }

          .contract-period_time {
            i {
              margin-right: 10px;
              font-size: 14px;
              color: color(bg, input-gray);
            }
          }

          .total-section {
            margin-top: 60px;
            border-top: $border-light;

            .total_title {
              font-weight: 600;
              font-size: 20px;
              color: #444447;
              letter-spacing: 0;
              line-height: 40px;
            }

            .total_price {
              font-weight: 900;
              font-size: 22px;
              color: #444447;
              letter-spacing: 0;
              text-align: right;
              line-height: 40px;

              span {
                font-weight: normal;
                font-size: 16px;
                color: color(typo, gray);
                line-height: 40px;
              }
            }
          }

          .contract-period_title,
          .active-accounts_title {
            width: 100%;

            span {
              font-weight: 700;
              font-size: 14px;
              color: color(typo, green);
              line-height: 24px;
              text-transform: none;
            }
          }

          .change-numbers {
            display: inline-flex;
            align-items: center;
            width: 48px;
            height: 24px;
            float: right;
            border-radius: 4px;
            color: color(typo, link);
            overflow: hidden;

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 24px;
              background-color: color(bg, light);
              font-size: 10px;

              &:hover {
                background-color: darken(color(bg, light), 2);
              }

              &.disabled {
                pointer-events: none;
                opacity: 0.4;
              }
            }
          }
        }

        .card-details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .note-field {
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            margin-left: 10px;

            label {
              margin-left: 0.25rem;
              font-size: 12px;
              color: color(typo, label);
              line-height: 20px;
            }

            textarea {
              flex-grow: 1;
              width: 100%;
              padding-left: 0.75rem;
              background: #f5f7fa;
              border: $border-light;
              border-radius: $radius-md;
              font-size: 14px;
              line-height: 26px;
              color: color(typo, dark);
              outline: none;
              transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

              &::placeholder {
                font-size: 14px;
                color: color(typo, light);
                letter-spacing: 0;
                line-height: 26px;
              }

              &:hover {
                border-color: darken($color: #87afec, $amount: 4);
              }

              &:focus {
                border: $border-blue;
              }

              &.disabled {
                background: darken($color: #f5f7fa, $amount: 4);
                opacity: 0.8;
              }
            }
          }

          .user-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            // border: $border-light;
            // box-shadow: $shadow-sm;
            box-shadow: 5px 5px 30px rgb(0 0 0 / 10%);
            padding: 20px;
            border-radius: 14px;

            h4 {
              margin-top: 0;
              margin-bottom: 10px;
              font-size: 16px;
              line-height: 1;
              text-transform: uppercase;
            }

            div {
              margin-bottom: 10px;

              &:last-of-type {
                margin-bottom: 0;
              }
            }

            span {
              display: inline-flex;

              &:first-child {
                width: 32%;
                font-weight: 600;
              }

              &:last-child {
                width: 68%;
                text-align: right;
                color: color(typo, gray);
              }
            }
          }

          .card-details_inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin-top: 40px;
            padding: 30px;
            background-image: linear-gradient(
              134deg,
              #232e3f 0%,
              hsl(216, 29%, 19%) 50%,
              hsl(216, 29%, 21%) 50%,
              hsl(216, 29%, 20%) 100%
            );
            box-shadow: 0 0 8px rgba(51, 51, 51, 0.4);
            border-radius: 14px;

            h4 {
              margin-top: 0;
              margin-bottom: 10px;
              line-height: 1;
              color: white;
              text-transform: uppercase;
            }
          }

          .card_title {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #b7bac9;
            text-transform: uppercase;
            line-height: 24px;
          }

          .card-example {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 200px;
            max-width: 400px;
            margin-bottom: 40px;
            padding: 30px;
            background-image: linear-gradient(134deg, #3023ae 0%, #c86dd7 100%);
            box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.2);
            border-radius: 14px;
            overflow: hidden;

            &::before {
              position: absolute;
              top: 100px;
              left: -100px;
              transform: rotate(24deg);
              content: "";
              display: block;
              width: 480px;
              height: 240px;
              border-radius: 50%;
              background-color: rgba(245, 247, 250, 0.1);
            }

            .card-provider {
              position: absolute;
              top: 20px;
              right: 20px;
              max-width: 80px;
              height: auto;
            }

            .card-number {
              margin-top: 60px;
              font-size: 24px;
              color: #b7bac9;
              letter-spacing: 8px;
              text-align: center;
              line-height: 24px;
            }

            .name-on-card {
              margin: 20px 0;
              font-weight: 600;
              font-size: 16px;
              color: #b7bac9;
              letter-spacing: 0;
              line-height: 16px;
            }

            .info {
              display: flex;
              justify-content: space-between;

              .card-expire,
              .cvc {
                font-weight: 600;
                font-size: 16px;
                color: #b7bac9;
                letter-spacing: 0;
                line-height: 16px;
              }
            }
          }

          .form {
            width: 100%;

            .input-field {
              position: relative;
              width: 100%;
              margin-bottom: 20px;

              label {
                margin-left: 0.25rem;
                font-size: 12px;
                color: color(typo, label);
                color: white;
                line-height: 20px;
              }

              textarea {
                width: 100%;
                height: 90px;
                background: #f5f7fa;
                background: transparent;
                border: 1px solid hsl(215deg 30% 32%);
                border-top: transparent;
                border-left: transparent;
                border-right: transparent;
                font-size: 14px;
                line-height: 26px;
                color: white;
                letter-spacing: 1px;
              }

              input {
                width: 100%;
                height: 36px;
                padding-left: 0.75rem;
                background: #f5f7fa;
                background: transparent;
                border: 1px solid hsl(215deg 30% 32%);
                border-top: transparent;
                border-left: transparent;
                border-right: transparent;
                font-size: 14px;
                line-height: 26px;
                color: white;
                letter-spacing: 1px;
                outline: none;
                transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

                &[type="number"] {
                  width: 100%;
                  padding-right: 5px;
                }

                &:hover {
                  border-color: darken($color: #f5f7fa, $amount: 8);
                }

                &:focus {
                  border-bottom: $border-blue;
                  /* border: ; */
                }

                &.disabled {
                  background: darken($color: #f5f7fa, $amount: 4);
                  opacity: 0.8;
                }

                &::placeholder {
                  color: #b7bac9;
                }
              }
            }

            .form-control-feedback {
              &.form-control-feedback {
                top: 21px;
                width: 36px;
                height: 36px;
                color: color(typo, gray);
              }
            }
          }

          .btn {
            width: 60%;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            font-weight: 600;
          }

          .btn:disabled {
            opacity: 0.5;
            cursor: default;
          }

          .error {
            display: flex;
            align-content: center;
            justify-content: center;
            margin-top: 20px;
            color: color(typo, red);

            svg {
              margin-right: 10px;
            }
          }
        }
      }

      footer {
        display: flex;
        justify-content: center;
        margin-top: auto;
        padding-top: 20px;
        font-size: 14px;
        line-height: 20px;
        color: color(typo, gray);

        a {
          margin-left: 5px;
          font-weight: 600;
          color: color(typo, dark);
        }
      }
    }

    .panel-right {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 40px 64px;
      background-image: linear-gradient(270deg, #ffffff 23%, #e4e9f1 100%);
      max-width: 460px;
      width: 460px;
      overflow: hidden;

      .cards {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 64px;
      }
    }
  }
}

.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
  display: flex;

  span {
    display: none;
  }

  &:hover {
    cursor: pointer;

    span {
      display: inline-flex;
      margin-left: 5px;
      font-size: 12px;
      line-height: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}

.btn-change_plan {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 40px;
  color: black;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
  opacity: 0.6;

  i {
    font-size: 12px;
    margin-left: 10px;
  }

  &:hover {
    background: black;
    color: white;
    opacity: 1;
  }
}
</style>
