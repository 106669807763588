/* eslint-disable */ 
export default {
    'arrows-alt-h': 'fas fa-arrows-alt-h',
    'copy-r': 'far fa-copy',
    'file-alt-r': 'far fa-file-alt',
    'file-alt-s': 'fas fa-file-alt',
    'calendar-alt-s': 'fas fa-calendar-alt',
    'calendar-alt-r': 'far fa-calendar-alt',
    'cog-s': 'fas fa-cog',
    'square-r': 'far fa-square',
    'check-square-r': 'far fa-check-square',
    'save-r': 'far fa-save',
    'save-s': 'fas fa-save',
    'cloud-download-alt-s': 'fas fa-cloud-download-alt',
    'check-s': 'fas fa-check',
    'times-s': 'fas fa-times',
    'search-s': 'fas fa-search',
    'caret-up-s': 'fas fa-caret-up',
    'caret-down-s': 'fas fa-caret-down',
    'caret-right-s': 'fas fa-caret-right',
    'caret-left-s': 'fas fa-caret-left',
    'user-secret-s': 'fas fa-user-secret',
    'lock-s': 'fas fa-lock',
    'unlock-s': 'fas fa-unlock',
    'trash-alt-s': 'fas fa-trash',
    'trash-s': 'fas fa-trash-alt',
    'paper-plane-s': 'fas fa-paper-plane',
    'spinner-s': 'fas fa-spinner',
    'exclamation-circle-s': 'fas fa-exclamation-circle',
    'exclamation-s': 'fas fa-exclamation',
    'circle-r': 'far fa-circle',
    'clock-r': 'far fa-clock',
    'pencil-alt-s': 'fas fa-pencil-alt',
    'calendar-check-r': 'far fa-calendar-check',
    'calendar-check-s': 'fas fa-calendar-check',
    'user-r': 'far fa-user',
    'user-s': 'fas fa-user',
    'sitemap-s': 'fas fa-sitemap',
    'tag-s': 'fas fa-tag',
    'address-book-r': 'far fa-address-book',
    'dna-s': 'fas fa-dna',
    'font-s': 'fas fa-font',
    'hourglass-half-s': 'fas fa-hourglass-half',
    'layer-group-s': 'fas fa-layer-group',
    'edit-r': 'far fa-edit',
    'plus-s': 'fas fa-plus',
    'undo-s': 'fas fa-undo',
    'minus-s': 'fas fa-minus',
    'sync-s': 'fas fa-sync',
    'sort-s': 'fas fa-sort',
    'file-contract-s': 'fas fa-file-contract',
    'file-contract-r': 'far fa-file-contract',
    'cloud-s': 'fas fa-cloud',
    'cloud-up-s': 'fas fa-cloud-upload-alt',
    'cloud-down-s': 'fas fa-cloud-download-alt',
    'users-s': 'fas fa-users',
    'question-s': 'fas fa-question',
    'sign-out-alt-s': 'fas fa-sign-out-alt',
    'globe-s': 'fas fa-globe',
    'bolt-s': 'fas fa-bolt',
    'check-square-s': 'fas fa-check-square',
    'print-s': 'fas fa-print',
    'file-r': 'far fa-file',
    'file-s': 'fas fa-file',
    'file-import-s': 'fas fa-file-import',
    'info-s': 'fas fa-info',
    'database-s': 'fas fa-database',
    'rocket-s': 'fas fa-rocket',
    'gavel-s': 'fas fa-gavel',
    'praying-hands-s': 'fas fa-praying-hands',
    'archive-s': 'fas fa-archive',
    'play-s': 'fas fa-play',
    'redo-alt-s': 'fas fa-redo-alt',
    'stopwatch-s': 'fas fa-stopwatch',
    'business-time-s': 'fas fa-business-time',
    'sync-alt-s': 'fas fa-sync-alt',
    'folder-open-s': 'fas fa-folder-open',
    'arrow-right-s': 'fas fa-arrow-right',
    'arrow-left-s': 'fas fa-arrow-left',
    'chevron-left-s': 'fas fa-chevron-left',
    'chevron-right-s': 'fas fa-chevron-right',
    'chevron-down-s': 'fas fa-chevron-down',
    'chevron-up-s': 'fas fa-chevron-up',
    'sort-down-s': 'fas fa-sort-down',
    'sort-up-s': 'fas fa-sort-up',
    'stop-s': 'fas fa-stop',
    'eye-r': 'far fa-eye',
    'eye-slash-r': 'far fa-eye-slash',
    'square-s': 'fas fa-square',
    'thumbtack-s': 'fas fa-thumbtack',
    'ellipsis-v': 'fa fa-ellipsis-v',
    'grip-v': 'fas fa-grip-vertical',
    'user-cog-s': 'fas fa-user-cog',
    'user-plus-s': 'fas fa-user-plus',
    'building-s': 'fas fa-building',
    'user-check-s': 'fas fa-user-check',
    'user-slash-s': 'fas fa-user-slash',
    'success': 'icon-check', // check in circle
    'error': 'icon-close', // x in circle
    'warning': 'icon-exclamation',
    'envelope-s': 'fas fa-envelope',
    'key-s': 'fas fa-key',
    'certificate-s': 'fas fa-certificate',
    'warning-triangle': 'fas fa-exclamation-triangle',
    'folder-s': 'fas fa-folder',
    'external-link-alt-s': 'fas fa-external-link-alt',
    'chart-bar-s': 'fas fa-chart-bar',
    'book-open': 'fas fa-book-open',
    'landmark': 'fas fa-landmark',
    'grid': 'fas fa-th-list',
    'sliders-s': 'fas fa-sliders-h',
    'code-s': 'fas fa-code',
    'filter-s': 'fas fa-filter',
    'ellipsis-v-s': 'fa fa-ellipsis-v',
    'expand-s': 'fas fa-expand',
    'compress-s': 'fas fa-compress',
    'question-circle': 'fas fa-circle-question',
    'info-circle': 'fas fa-circle-info'
}
